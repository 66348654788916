import { Button, chakra, FormControl, FormErrorMessage, HStack, useToast } from '@chakra-ui/react'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useGetAppQuery, useUpdateAppMutation } from '../../../graphql'
import { Section, SectionTitle, SectionDescription, SectionInput, SectionFooter } from '../setting-section'
import { ConfirmToast } from '../ConfirmToast'
import { CancelSettingButton, SaveSettingButton } from '../settings-buttons'

type FormData = {
  name: string
}

function AppNameFormSkeleton() {
  return (
    <Section w="100%" mt={0}>
      <SectionTitle isLoaded={false}>App Name</SectionTitle>
      <SectionDescription isLoaded={false} noOfLines={1}>
        Name of your shop or app that will host the releases.
      </SectionDescription>

      <HStack spacing={4}>
        <FormControl width="auto">
          <SectionInput isDisabled placeholder="My Shop" />
        </FormControl>
      </HStack>
      <SectionFooter>
        <Button colorScheme="primary" size="sm" isDisabled>
          Save
        </Button>
      </SectionFooter>
    </Section>
  )
}

type AppNameSettingFormProps = {
  defaultValues: FormData
}

function AppNameSettingForm({ defaultValues }: AppNameSettingFormProps) {
  const [update, { loading: saving }] = useUpdateAppMutation()
  const toast = useToast()
  const appId = useMemo(() => {
    return localStorage.getItem('appId')
  }, [])

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, errors },
  } = useForm<FormData>({
    defaultValues,
  })

  const handleReset = useCallback(() => {
    reset()
  }, [reset])

  const onSubmit = useCallback(
    async (data: FormData) => {
      try {
        const result = await update({
          variables: {
            data: {
              name: data.name,
            },
            id: appId!,
          },
        })
        toast({
          description: 'App name updated successfully.',
          status: 'info',
          render: ConfirmToast,
          duration: 5000,
          isClosable: true,
        })
        reset({
          name: result.data?.updateApp.name,
        })
      } catch (e) {
        toast({
          title: 'Error updating name, please try again later.',
          description: `${(e as any).message}.`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    },
    [appId, reset, toast, update]
  )

  return (
    <Section w="100%">
      <chakra.form onSubmit={handleSubmit(onSubmit)}>
        <SectionTitle>App Name</SectionTitle>
        <SectionDescription>Name of your shop or app that will host the releases.</SectionDescription>
        <HStack spacing={4} alignItems="flex-start">
          <FormControl width="auto" isInvalid={!!errors?.name}>
            <SectionInput
              isDisabled={saving}
              {...register('name', {
                required: 'Name is required',
              })}
              placeholder="John"
            />
            {!!errors.name && <FormErrorMessage pb={5}>{errors.name.message}</FormErrorMessage>}
          </FormControl>
        </HStack>

        <SectionFooter>
          {isDirty && <CancelSettingButton onClick={handleReset}>Cancel</CancelSettingButton>}
          <SaveSettingButton type="submit" isDisabled={!isDirty || saving} isLoading={saving}>
            Save
          </SaveSettingButton>
        </SectionFooter>
      </chakra.form>
    </Section>
  )
}

export type AppNameSettingProps = {}

export function AppNameSetting(props: AppNameSettingProps) {
  const appId = useMemo(() => {
    return localStorage.getItem('appId')
  }, [])
  const { loading, data } = useGetAppQuery({
    variables: {
      id: appId!,
    },
    skip: !appId,
  })

  const defaultValues = useMemo<FormData>(() => {
    return {
      name: data?.app?.name ?? '',
    }
  }, [data])

  if (loading) {
    return <AppNameFormSkeleton />
  }

  return <AppNameSettingForm defaultValues={defaultValues} />
}
