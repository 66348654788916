import { FC, useCallback, useEffect, useState } from 'react'
import { gql_Query, gql_Release, gql_ReleaseEntry, useGetReleaseStatsQuery } from '../../../graphql'
import { CombinationsOverview } from './CombinationsOverview'
import { EntryStatusFilter } from './EntryStatusFilter'
import { DataTable, DataTableValue as Field, Input, List, useVersionStateValue } from 'chakra-admin'
import { QUERY_GET_RELEASE_ENTRIES } from '../../../queries'
import { EntryStatusField } from './EntryStatusField'
import { Box, VStack, useBreakpointValue } from '@chakra-ui/react'
import { Filters } from '../../../components/list/Filters'
import { Text } from '@chakra-ui/react'
import { ReleaseDrawQueueStatusCard, ReleaseFullfillmentQueueStatusCard } from './ReleaseQueueStatusCard'
import { QueueWatcher } from './QueueWatcher'

export type EntriesTableProps = {
  id: string
  record: Partial<gql_Release>
}

export function EntriesTable({ id, record, ...props }: EntriesTableProps) {
  const {
    loading: statsLoading,
    data: statsData,
    refetch,
  } = useGetReleaseStatsQuery({
    variables: {
      id: record?.id || '',
    },
    skip: !record?.id,
    fetchPolicy: 'network-only',
  })

  const [statusFilter, setStatusFilter] = useState<'all' | 'pending' | 'selected' | 'confirmed' | 'rejected'>('all')

  const handleStatusFilterChange = useCallback((value: string) => {
    setStatusFilter(value as any)
  }, [])

  const version = useVersionStateValue()
  useEffect(() => {
    if (!statsLoading && refetch) {
      refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version])

  return (
    <>
      <QueueWatcher id={record?.id} refetch={refetch} />
      <VStack spacing={4} align="stretch">
        <ReleaseDrawQueueStatusCard id={record?.id} mx={4} my={4} />
        <ReleaseFullfillmentQueueStatusCard
          id={record?.id}
          mx={4}
          my={4}
          totalSelectedForConfirmation={statsData?.releaseStats?.selectedForConfirmation || 0}
        />
      </VStack>
      <CombinationsOverview record={record} releaseStats={statsData?.releaseStats} />
      <EntryStatusFilter
        record={record}
        value={statusFilter}
        onChange={handleStatusFilterChange as any}
        name="status"
        releaseStats={statsData?.releaseStats}
      />
      <Box mx={4}>
        <List<gql_Query, gql_ReleaseEntry>
          query={QUERY_GET_RELEASE_ENTRIES}
          queryOptions={{
            fetchPolicy: 'network-only',
          }}
          resource="ReleaseEntry"
          paginationMode="cursor"
          hasCreate={false}
          hasEdit={false}
          hasList
          hasShow={false}
          hasDelete={false}
          defaultPerPage={100}
          defaultFilters={{ status: statusFilter === 'all' ? undefined : statusFilter, releaseId: record.id }}
          toolbarComponent={<></>}
          title={(<></>) as any}
          filtersComponent={<EntriesTableFilters />}
        >
          <DataTable
            tableProps={{
              size: 'sm',
            }}
            rowClick={false}
            moreMenuComponent={<></>}
          >
            <Field
              source="customerFirstName"
              render={({ record }: any) => {
                return <>{`${record.customerFirstName} ${record.customerLastName}`}</>
              }}
              label="Nome Cliente"
            />
            <Field label="E-mail cliente" source="customerEmail" />
            <Field
              source="shippingAddress"
              label="Indirizzo di spedizione"
              render={({ record }: any) => {
                return (
                  <>
                    <Text pt={1} fontSize="xs">
                      {record?.data?.shipping_address?.first_name} {record?.data?.shipping_address?.last_name} (
                      {record.data?.shipping_address?.phone})
                    </Text>
                    <Text pb={1} fontSize="xs" mt={1}>
                      {record.data?.shipping_address?.address1}
                      {record.data?.shipping_address?.address2 && (
                        <>
                          <br />
                          {record.data?.shipping_address?.address2}
                        </>
                      )}
                      <br />
                      {record.data?.shipping_address?.city},{' '}
                      {record.data?.shipping_address?.state && (
                        <>{record.data?.shipping_address?.state?.iso?.substring?.(3)}, </>
                      )}{' '}
                      {record.data?.shipping_address?.zip}, {record.data?.shipping_address?.country?.name}
                    </Text>
                  </>
                )
              }}
            />
            <Field
              source="billingAddress"
              label="Indirizzo di fatturazione"
              render={({ record }: any) => {
                return (
                  <>
                    <Text fontSize="xs">
                      {record?.data?.billing_address?.first_name} {record?.data?.billing_address?.last_name} (
                      {record.data?.billing_address?.phone})
                    </Text>
                    <Text fontSize="xs" mt={1}>
                      {record.data?.billing_address?.address1}
                      {record.data?.billing_address?.address2 && (
                        <>
                          <br />
                          {record.data?.billing_address?.address2}
                        </>
                      )}
                      <br />
                      {record.data?.billing_address?.city},{' '}
                      {record.data?.billing_address?.state && (
                        <>{record.data?.billing_address?.state?.iso?.substring?.(3)}, </>
                      )}{' '}
                      {record.data?.billing_address?.zip}, {record.data?.billing_address?.country?.name}
                    </Text>
                  </>
                )
              }}
            />
            <Field
              label="Combinazione"
              source="combinationId"
              render={({ record }: any) => {
                return <>{record.data?.combination?.label}</>
              }}
            />
            <Field
              source="createdAt"
              label="Creato il"
              render={({ record }: any) => <>{new Date(record.createdAt).toLocaleString()}</>}
            />
            <Field
              source="status"
              label="Stato"
              render={({ record }: any) => {
                return <EntryStatusField record={record} />
              }}
            />
          </DataTable>
        </List>
      </Box>
    </>
  )
}

const EntriesTableFilters: FC = (props) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  return (
    <Filters {...props}>
      <Input
        source="name"
        label="Nome"
        placeholder="Cerca per nome..."
        alwaysOn={isMobile ? false : true}
        border="none"
        bgColor="transparent"
        _hover={{ bgColor: 'transparent' }}
        borderRadius="xl"
        boxShadow="0px 1px 4px rgba(0, 0, 0, 0.05), 0px 6px 24px rgba(0, 0, 0, 0.04), inset 0px 1px 1px rgba(0, 0, 0, 0.04)"
      />
    </Filters>
  )
}
