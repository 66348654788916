import { FC, ReactNode } from 'react'
import { Box, BoxProps, Container, DrawerBody } from '@chakra-ui/react'
import { PageTitle } from './PageTitle'

export type FormPageLayoutProps = {
  title?: ReactNode
  renderingInModal?: boolean
} & BoxProps

export const FormPageLayout: FC<FormPageLayoutProps> = ({
  children,
  renderingInModal,
  title = <PageTitle />,

  ...rest
}) => {
  //   const chechScroll = Object.keys(rest).length === 0
  if (renderingInModal) {
    return (
      <>
        {typeof title === 'string' ? <PageTitle label={title} renderingInModal={renderingInModal} /> : title || null}
        <DrawerBody {...rest}>{children}</DrawerBody>
      </>
    )
  }

  return (
    <Container maxWidth="container.xl" {...rest}>
      <Box width="100%">
        {typeof title === 'string' ? <PageTitle label={title} renderingInModal={renderingInModal} /> : title || null}
      </Box>
      {children}
    </Container>
  )
}
