import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, Image, Text, Tooltip } from '@chakra-ui/react'
import { useCallback, useMemo } from 'react'
import {
  gql_CreateStripeAccountMutation,
  gql_MerchantStripeAccount,
  useCreateStripeAccountMutation,
} from '../../graphql'
import { StripeAccountsList } from '../stripe/StripeAccountsList'

export type StripeOnboardingProps = {
  onStripeAccountCreated: (stripeAccount: gql_CreateStripeAccountMutation['createMerchantStripeAccount']) => void
  stripeAccounts: Pick<gql_MerchantStripeAccount, 'id' | 'stripeAccount' | 'stripeAccountId'>[]
}

export function StripeOnboarding({ onStripeAccountCreated, stripeAccounts }: StripeOnboardingProps) {
  const [createStripeAccount, { loading, data, error }] = useCreateStripeAccountMutation({
    onCompleted: (data) => {
      window.location.href = data.createMerchantStripeAccount.stripeOnboardingUrl
    },
  })
  const waiting = useMemo(() => loading || !!data?.createMerchantStripeAccount?.stripeOnboardingUrl, [loading, data])

  const handleClick = useCallback(() => {
    createStripeAccount()
  }, [createStripeAccount])

  return (
    <Box display="flex" flexDir="column" justifyContent="center">
      Sneakpeek uses Stripe to process payments. You can create a Stripe account or use an already existing one to start
      accepting payments.
      {!stripeAccounts?.length ? (
        <Box
          display="flex"
          borderRadius="lg"
          border="1px solid"
          borderColor="gray.200"
          p={4}
          m={4}
          pl={0}
          alignItems="center"
          height="85px"
        >
          <Image src="/images/stripe-full.svg" height="70px" borderColor="transparent" alt="Stripe Logo" mr={4} />

          <Text flex="1" color="blackAlpha.600" fontSize="sm">
            Configure Stripe to start accepting payments.
          </Text>

          <Button variant="outline" mr={3} size="sm" onClick={handleClick} isDisabled={waiting} isLoading={waiting}>
            Configure Stripe
          </Button>
        </Box>
      ) : (
        <StripeAccountsList stripeAccounts={stripeAccounts} />
      )}
    </Box>
  )
}
