import { Avatar, Box, Icon, Text } from '@chakra-ui/react'
import { useCallback } from 'react'
import { HiOutlineChevronRight } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import { gql_App } from '../../graphql'

type AppRowProps = {
  app: Pick<gql_App, 'id' | 'name' | 'url' | 'createdAt'>
}

export function AppRow({ app }: AppRowProps) {
  const navigate = useNavigate()

  const handleClick = useCallback(() => {
    window?.localStorage?.setItem('appId', app.id)
    navigate('/', { replace: true })
    navigate(0)
  }, [app.id, navigate])

  return (
    <Box
      display="flex"
      flex="1"
      width="100%"
      border="1px solid"
      borderColor="gray.100"
      borderRadius="lg"
      alignItems="center"
      p={4}
      role="button"
      _hover={{ bg: 'gray.50' }}
      onClick={handleClick}
    >
      <Avatar name={app.name} />
      <Text flex="1" pl={8} fontWeight="bold">
        {app.name}
      </Text>

      <Icon as={HiOutlineChevronRight} color="gray.400" />
    </Box>
  )
}
