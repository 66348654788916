/* eslint-disable react/jsx-key */
import React, { FC } from 'react'
import {
  DataTableValue as Field,
  GenericMoreMenuButton,
  List,
  ListToolbar,
  Filters,
  DataTable,
  AutocompleteInput,
} from 'chakra-admin'
import { Text } from '@chakra-ui/react'
import { QUERY_GET_RELEASES, QUERY_GET_RELEASE_ENTRIES } from '../../queries'
import { gql_Query, gql_ReleaseEntry } from '../../graphql'
import { useBreakpointValue } from '@chakra-ui/react'
import { CreateButton } from '../../components/buttons/CreateButton'

const AttendanceFilters: FC = (props) => {
  return (
    <Filters {...props}>
      <AutocompleteInput
        {...props}
        paginationMode="cursor"
        inputStyleProps={{
          variant: 'outline',
          borderRadius: 'lg',
        }}
        labelStyleProps={{
          display: 'none',
        }}
        toggleButtonStyleProps={{
          display: 'none',
        }}
        query={QUERY_GET_RELEASES}
        source="releaseId"
        label="Release"
        placeholder="Seleziona Release"
        disableCreateItem
        showFormControl={false}
        inputValueToFilters={(name: string) => ({ name })}
        dataItemToAutocompleteItem={(data: any) => ({
          ...data,
          label: data.name,
          value: data.id,
        })}
        alwaysOn
      />
    </Filters>
  )
}

export const AttendanceList: FC = (props) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  return (
    <List<gql_Query, gql_ReleaseEntry>
      paginationMode="cursor"
      query={QUERY_GET_RELEASE_ENTRIES}
      // deleteItemMutation={MUTATION_DELETE_PRODUCT}
      filtersComponent={<AttendanceFilters />}
      toolbarComponent={
        <ListToolbar>
          <CreateButton label={isMobile ? 'Crea' : true} />
        </ListToolbar>
      }
      {...props}
    >
      <DataTable
        tableProps={{
          size: 'sm',
        }}
        moreMenuComponent={<GenericMoreMenuButton openEditAsModal />}
      >
        <Field source="id" />
        <Field source="releaseId" />
        <Field
          source="customerFirstName"
          render={({ record }: any) => {
            return <>{`${record.customerFirstName} ${record.customerLastName}`}</>
          }}
          label="Nome Cliente"
        />
        <Field label="E-mail cliente" source="customerEmail" />
        <Field
          source="shippingAddress"
          label="Indirizzo di spedizione"
          render={({ record }: any) => {
            return (
              <>
                <Text pt={1} fontSize="xs">
                  {record?.data?.shipping_address?.first_name} {record?.data?.shipping_address?.last_name} (
                  {record.data?.shipping_address?.phone})
                </Text>
                <Text pb={1} fontSize="xs" mt={1}>
                  {record.data?.shipping_address?.address1}
                  {record.data?.shipping_address?.address2 && (
                    <>
                      <br />
                      {record.data?.shipping_address?.address2}
                    </>
                  )}
                  <br />
                  {record.data?.shipping_address?.city},{' '}
                  {record.data?.shipping_address?.state && (
                    <>{record.data?.shipping_address?.state?.iso?.substring?.(3)}, </>
                  )}{' '}
                  {record.data?.shipping_address?.zip}, {record.data?.shipping_address?.country?.name}
                </Text>
              </>
            )
          }}
        />
        <Field
          source="billingAddress"
          label="Indirizzo di fatturazione"
          render={({ record }: any) => {
            return (
              <>
                <Text fontSize="xs">
                  {record?.data?.billing_address?.first_name} {record?.data?.billing_address?.last_name} (
                  {record.data?.billing_address?.phone})
                </Text>
                <Text fontSize="xs" mt={1}>
                  {record.data?.billing_address?.address1}
                  {record.data?.billing_address?.address2 && (
                    <>
                      <br />
                      {record.data?.billing_address?.address2}
                    </>
                  )}
                  <br />
                  {record.data?.billing_address?.city},{' '}
                  {record.data?.billing_address?.state && (
                    <>{record.data?.billing_address?.state?.iso?.substring?.(3)}, </>
                  )}{' '}
                  {record.data?.billing_address?.zip}, {record.data?.billing_address?.country?.name}
                </Text>
              </>
            )
          }}
        />
        <Field
          label="Combinazione"
          source="combinationId"
          render={({ record }: any) => {
            return <>{record.data?.combination?.label}</>
          }}
        />
        <Field
          source="createdAt"
          label="Creato il"
          render={({ record }: any) => <>{new Date(record.createdAt).toLocaleString()}</>}
        />
        {/* <Field
          source="status"
          label="Stato"
          render={({ record }: any) => {
            return <EntryStatusField record={record} />
          }}
        /> */}
      </DataTable>
    </List>
  )
}
