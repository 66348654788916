import React, { FC, useCallback } from 'react'
import { chakra, ChakraProps, Box, Container, Stack, Divider } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { CancelButton } from '../buttons/CancelButton'
import { SubmitButton } from '../buttons/SubmitButton'
import { filterChakraProps, TreeRenderer, UseCreateResult, UseEditResult } from 'chakra-admin'

type BaseFormProps = {
  children?: React.ReactNode
  defaultValues?: any
  resource?: string
  renderingInModal?: boolean
} & Partial<UseCreateResult | UseEditResult> &
  ChakraProps

export const BaseForm: FC<BaseFormProps> = ({
  children,
  defaultValues,
  onSubmit: onSubmitProp = () => {},
  mutationResult,
  executeMutation,
  resource,
  renderingInModal,
  ...rest
}) => {
  const methods = useForm({ defaultValues })
  const { handleSubmit } = methods

  const onSubmit = useCallback(
    (values: any) => {
      onSubmitProp({ __typename: resource, ...values })
    },
    [onSubmitProp, resource]
  )

  return (
    <chakra.form onSubmit={handleSubmit(onSubmit)}>
      <Container maxWidth="container.lg">
        <chakra.div mb={renderingInModal ? '76px' : 0}>
          <TreeRenderer
            children={children}
            propsOverride={{
              ...filterChakraProps(rest),
              setValue: methods.setValue,
              register: methods.register,
              unregister: methods.unregister,
              control: methods.control,
              formState: methods.formState,
              resource,
            }}
          />
        </chakra.div>
      </Container>

      <Box
        zIndex="1"
        position={renderingInModal ? 'fixed' : 'sticky'}
        bottom={8}
        w="100%"
        height="30px"
        boxShadow={renderingInModal ? 'main' : 'none'}
        bg={renderingInModal ? 'white' : 'transparent'}
        p={renderingInModal ? 4 : 0}
      >
        <Divider />
        <Stack py={4} spacing={4} direction="row" justifyContent="end" bgColor="white">
          <CancelButton />
          <SubmitButton
            type="submit"
            isDisabled={mutationResult?.loading || !methods.formState.isDirty}
            isLoading={mutationResult?.loading}
          />
        </Stack>
      </Box>
    </chakra.form>
  )
}
