import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, VStack } from '@chakra-ui/react'
import { useCallback } from 'react'
import { useGetAppsQuery } from '../../graphql'
import { AppOnboardingCreate } from './AppOnboardingCreate'
import { AppRow } from './AppRow'

export function AppMenu() {
  const { loading, data, error, refetch } = useGetAppsQuery({
    variables: {
      first: 20,
    },
  })

  const handleAppCreated = useCallback(() => {
    refetch()
  }, [refetch])

  if (loading) {
    return <Box>Loading...</Box>
  }

  if (error?.message) {
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{error.message}</AlertDescription>
      </Alert>
    )
  }

  if (!data?.result?.nodes.length) {
    return <AppOnboardingCreate onAppCreated={handleAppCreated} />
  }

  return (
    <Box>
      <VStack width="500px">
        {data?.result?.nodes.map((item) => {
          return <AppRow key={item.id} app={item} />
        })}
      </VStack>
    </Box>
  )
}
