import { useEffect, useMemo, useState } from 'react'
import { gql_Release } from '../../../graphql'
import { Alert, AlertIcon, AlertProps, chakra } from '@chakra-ui/react'

export type ReleaseRunningAlertProps = {
  record: Omit<Partial<gql_Release>, 'startDate' | 'endDate'> & {
    startDate?: Date
    endDate?: Date
  }
} & AlertProps

export function ReleaseRunningAlert({ record, ...props }: ReleaseRunningAlertProps) {
  const [now, setNow] = useState(new Date())

  const isRaffleRunning = useMemo(() => {
    if (!record || !record?.startDate || !record?.endDate) {
      return false
    }

    return record.startDate < now && record.endDate > now
  }, [now, record])

  useEffect(() => {
    // update now variable every 10 seconds

    const interval = setInterval(() => {
      setNow(new Date())
    }, 10000)

    return () => clearInterval(interval)
  }, [])

  if (!isRaffleRunning || record.status !== 'OPEN') {
    return null
  }

  return (
    <Alert status="warning" {...props}>
      <AlertIcon />
      <chakra.strong mr={2}>Attenzione! </chakra.strong> La release è in corso, potresti voler attendere la fine prima
      di selezionare i vincitori.
    </Alert>
  )
}
