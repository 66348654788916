import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Button,
  Circle,
  Menu,
  MenuButton,
  MenuItem as ChakraMenuItem,
  MenuList,
  useToast,
  CircularProgress,
} from '@chakra-ui/react'
import { gql_ReleaseEntry, useUpdateReleaseEntryStatusMutation } from '../../../graphql'
import { useCallback } from 'react'
import { useVersion } from 'chakra-admin'

const STATUS_COLORS = {
  WAITING: 'var(--chakra-colors-gray-400)',
  SELECTED_FOR_CONFIRMATION: 'var(--chakra-colors-blue-600)',
  SELECTED_FOR_REJECTION: 'var(--chakra-colors-red-400)',
  CONFIRMED: 'var(--chakra-colors-green-600)',
  REJECTED: 'var(--chakra-colors-red-600)',
}

const STATUS_LABELS = {
  WAITING: 'In Attesa',
  SELECTED_FOR_CONFIRMATION: 'Selezionato per la vincita',
  SELECTED_FOR_REJECTION: 'Selezionato per il rifiuto',
  CONFIRMED: 'Confermato',
  REJECTED: 'Rifiutato',
}

const MENU_ITEMS = [
  {
    label: STATUS_LABELS.WAITING,
    value: 'WAITING',
    color: STATUS_COLORS.WAITING,
    isDisabled: (value: string) => value === 'CONFIRMED' || value === 'REJECTED' || value === 'WAITING',
  },
  {
    label: STATUS_LABELS.SELECTED_FOR_CONFIRMATION,
    value: 'SELECTED_FOR_CONFIRMATION',
    color: STATUS_COLORS.SELECTED_FOR_CONFIRMATION,
    isDisabled: (value: string) =>
      value === 'CONFIRMED' || value === 'REJECTED' || value === 'SELECTED_FOR_CONFIRMATION',
  },
  {
    label: STATUS_LABELS.SELECTED_FOR_REJECTION,
    value: 'SELECTED_FOR_REJECTION',
    color: STATUS_COLORS.SELECTED_FOR_REJECTION,
    isDisabled: (value: string) => value === 'CONFIRMED' || value === 'REJECTED' || value === 'SELECTED_FOR_REJECTION',
  },
]

export type EntryStatusFieldProps = {
  record?: Partial<gql_ReleaseEntry>
}

export function EntryStatusField({ record, ...props }: EntryStatusFieldProps) {
  const [update, { loading, data, error }] = useUpdateReleaseEntryStatusMutation()
  const nextVersion = useVersion()
  const toast = useToast()

  const handleClick = useCallback(
    async (value: string) => {
      try {
        await update({
          variables: {
            id: record?.id || '',
            releaseId: record?.releaseId || '',
            status: value as any,
          },
        })
        nextVersion()
      } catch (error) {
        console.log(error)
        toast({
          title: 'Errore',
          description: (error as any).message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    },
    [nextVersion, record?.id, record?.releaseId, toast, update],
  )

  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="ghost"
        size="sm"
        maxW="220px"
        fontWeight="normal"
        textOverflow="ellipsis"
        rightIcon={<ChevronDownIcon />}
        leftIcon={<Circle size="8px" bgColor={(STATUS_COLORS as any)[record?.status || 'WAITING']} />}
      >
        {(STATUS_LABELS as any)[record?.status || 'WAITING']}
      </MenuButton>
      <MenuList>
        {MENU_ITEMS.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            isDisabled={item.isDisabled(record?.status || 'WAITING')}
            icon={loading ? <CircularProgress /> : <Circle size="8px" bgColor={item.color} />}
            onClick={handleClick}
          >
            {item.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}

type MenuItemProps = {
  icon: React.ReactNode
  isDisabled?: boolean
  children: React.ReactNode
  value: string
  onClick: (value: string) => void
}

function MenuItem({ icon, isDisabled, children, value, onClick }: MenuItemProps) {
  const handleClick = useCallback(() => {
    onClick(value)
  }, [onClick, value])

  return (
    <ChakraMenuItem isDisabled={isDisabled} onClick={handleClick} icon={icon as any}>
      {children}
    </ChakraMenuItem>
  )
}
