import { Divider, Heading, Text, Stack, Box } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useGetAppQuery } from '../../../../graphql'
import { ShopifyIntegration } from './shopify-integration'

export type AppIntegrationsSettingsProps = {}

export function AppIntegrationsSettings(props: AppIntegrationsSettingsProps) {
  const appId = useMemo(() => {
    return localStorage.getItem('appId') || undefined
  }, [])

  const { loading, data } = useGetAppQuery({
    variables: {
      id: appId!,
    },
    skip: !appId,
  })

  const hasShopifyIntegration = useMemo(() => {
    return data?.app?.activeIntegrations.includes('shopify')
  }, [data])

  return (
    <Stack>
      <Box flex="1" pb={8}>
        <Heading color="blackAlpha.700" as="h2" size="lg">
          Available Integrations
        </Heading>
        <Text pb={4} color="blackAlpha.600">
          Integrate SneakPeek with external ecommerce platforms and services.
        </Text>
        <Divider />
      </Box>

      <ShopifyIntegration appId={appId!} loading={!appId || loading} hasShopifyIntegration={hasShopifyIntegration} />
    </Stack>
  )
}
