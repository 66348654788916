import { gql } from '@apollo/client'

export const fragments = {
  StaffMember: gql`
    fragment StaffMemberFragment on StaffMember {
      id
      email
      firstName
      lastName
      merchantId
      merchant {
        id
        name
        stripeAccounts {
          id
          stripeAccountId
          stripeAccount
          createdAt
          updatedAt
        }
        active
      }

      createdAt
      updatedAt
    }
  `,
  App: gql`
    fragment AppFragment on App {
      id
      name
      url
      active
      activeIntegrations
      createdAt
      updatedAt
    }
  `,
  MerchantStripeAccount: gql`
    fragment MerchantStripeAccountFragment on MerchantStripeAccount {
      id
      stripeAccountId
      stripeAccount
      merchantId
      createdAt
    }
  `,
  Release: gql`
    fragment ReleaseFragment on Release {
      id
      startDate
      endDate
      name
      productId
      dropType
      isPublished
      status
      selectionData
      product {
        id
        name
        integrationCode
        taxRate
        productAttributes {
          id
          order
          attribute {
            id
            integrationCode
            name
          }
          productAttributeValues {
            id
            value
            order
            productAttributeId
          }
        }
        productCombinations {
          id
          price
          label
          priceTaxes
          productCombinationAttrValues {
            productCombinationId
            productAttributeValueId
          }
        }
        active
      }
      createdAt
      updatedAt
    }
  `,
  ReleaseEntry: gql`
    fragment ReleaseEntryFragment on ReleaseEntry {
      id
      appId
      productId
      releaseId
      customerFirstName
      customerLastName
      customerEmail
      data
      status
      createdAt
      updatedAt
    }
  `,
  ExternalProduct: gql`
    fragment ExternalProductFragment on ExternalProduct {
      id
      title
      description
      isActive
      featuredImage {
        id
        altText
        url
        width
        height
      }
    }
  `,

  Product: gql`
    fragment ProductFragment on Product {
      id
      name
      brand
      taxRate
      description
      active
      onlineStoreUrl
      productImages {
        id
        altText
        url
        width
        height
      }
      productAttributes {
        id
        productId
        attributeId
        order
        attribute {
          id
          integrationCode
          name
        }
        productAttributeValues {
          id
          value
          order
          productAttributeId
        }
      }
    }
  `,
}

/** AUTH */
export const QUERY_GET_STAFF_MEMBER_ME = gql`
  ${fragments.StaffMember}

  query GetStaffMemberMe {
    staffMemberMe {
      ...StaffMemberFragment
    }
  }
`

export const MUTATION_LOGIN = gql`
  ${fragments.StaffMember}

  mutation Login($credentials: Credentials!) {
    login(credentials: $credentials) {
      user {
        ...StaffMemberFragment
      }
    }
  }
`

export const MUTATION_ADMIN_LOGIN = gql`
  ${fragments.StaffMember}
  mutation Impersonate($code: String!) {
    staffMemberImpersonate(code: $code) {
      user {
        ...StaffMemberFragment
      }
    }
  }
`

export const MUTATION_LOGOUT = gql`
  mutation Logout {
    logout
  }
`

/** APP */
export const QUERY_GET_APP = gql`
  ${fragments.App}

  query GetApp($id: ID!) {
    app(id: $id) {
      ...AppFragment
    }
  }
`

export const QUERY_GET_APPS = gql`
  query GetApps(
    $after: String
    $before: String
    $filters: AppFilters
    $first: Int
    $last: Int
    $revert: Boolean
    $sortBy: AppSortByKey
  ) {
    total: appsCount(filters: $filters)
    result: apps(
      after: $after
      before: $before
      filters: $filters
      first: $first
      last: $last
      revert: $revert
      sortBy: $sortBy
    ) {
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      nodes {
        ...AppFragment
      }
      edges {
        cursor
        node {
          ...AppFragment
        }
      }
    }
  }

  ${fragments.App}
`

export const MUTATION_CREATE_APP = gql`
  ${fragments.App}
  mutation CreateApp($data: CreateAppData!) {
    createApp(data: $data) {
      ...AppFragment
    }
  }
`

export const MUTATION_UPDATE_APP = gql`
  ${fragments.App}
  mutation UpdateApp($id: ID!, $data: UpdateAppData!) {
    updateApp(id: $id, data: $data) {
      ...AppFragment
    }
  }
`

// export const MUTATION_DELETE_APP = gql`
//   mutation DeleteApp($id: ID!) {
//     deleteApp(id: $id)
//   }
// `

/** STRIPE_ACCOUNT */
export const MUTATION_CREATE_STRIPE_ACCOUNT = gql`
  ${fragments.MerchantStripeAccount}
  mutation CreateStripeAccount {
    createMerchantStripeAccount {
      merchantStripeAccount {
        ...MerchantStripeAccountFragment
      }
      stripeOnboardingUrl
    }
  }
`

export const MUTATION_CREATE_STRIPE_ACCOUNT_LINK = gql`
  mutation CreateStripeAccountLink($merchantStripeAccountId: ID!) {
    createStripeAccountLink(merchantStripeAccountId: $merchantStripeAccountId)
  }
`

export const MUTATION_UPDATE_STAFF_MEMBER_ME_EMAIL = gql`
  ${fragments.StaffMember}
  mutation UpdateStaffMemberMeEmail($email: String!) {
    updateStaffMemberMeEmail(email: $email) {
      ...StaffMemberFragment
    }
  }
`

export const MUTATION_UPDATE_STAFF_MEMBER_ME_NAME = gql`
  ${fragments.StaffMember}
  mutation UpdateStaffMemberMeName($firstName: String!, $lastName: String!) {
    updateStaffMemberMeName(firstName: $firstName, lastName: $lastName) {
      ...StaffMemberFragment
    }
  }
`

export const MUTATION_UPDATE_STAFF_MEMBER_ME_PASSWORD = gql`
  mutation UpdateStaffMemberMePassword($oldPassword: String!, $password: String!) {
    updateStaffMemberMePassword(oldPassword: $oldPassword, password: $password) {
      id
    }
  }
`

// RELEASES
export const QUERY_GET_RELEASES = gql`
  ${fragments.Release}
  query GetReleases(
    $after: String
    $before: String
    $filters: ReleaseFilters
    $first: Int
    $last: Int
    $revert: Boolean
    $sortBy: ReleaseSortByKey
  ) {
    total: releasesCount(filters: $filters)
    result: releases(
      after: $after
      before: $before
      filters: $filters
      first: $first
      last: $last
      revert: $revert
      sortBy: $sortBy
    ) {
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }

      nodes {
        ...ReleaseFragment
      }

      edges {
        cursor
        node {
          ...ReleaseFragment
        }
      }
    }
  }
`

export const QUERY_GET_RELEASE = gql`
  ${fragments.Release}
  query GetRelease($id: ID!) {
    release(id: $id) {
      ...ReleaseFragment
    }
  }
`

export const MUTATION_CREATE_RELEASE = gql`
  ${fragments.Release}
  mutation CreateRelease($data: CreateReleaseData!) {
    createRelease(data: $data) {
      ...ReleaseFragment
    }
  }
`

export const MUTATION_UPDATE_RELEASE = gql`
  ${fragments.Release}
  mutation UpdateRelease($id: ID!, $data: UpdateReleaseData!) {
    updateRelease(id: $id, data: $data) {
      ...ReleaseFragment
    }
  }
`

export const MUTATION_DELETE_RELEASE = gql`
  mutation DeleteRelease($id: ID!) {
    deleteRelease(id: $id)
  }
`

// EXTERNAL PRODUCTS
export const QUERY_GET_EXTERNAL_PRODUCTS = gql`
  ${fragments.ExternalProduct}

  query GetExternalProducts(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $revert: Boolean
    $filters: ExternalProductFilters
  ) {
    result: externalProducts(
      after: $after
      before: $before
      first: $first
      last: $last
      revert: $revert
      filters: $filters
    ) {
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }

      nodes {
        ...ExternalProductFragment
      }

      edges {
        cursor
        node {
          ...ExternalProductFragment
        }
      }
    }
  }
`

// PRODUCTS
export const QUERY_GET_PRODUCTS = gql`
  ${fragments.Product}
  query GetProducts(
    $after: String
    $before: String
    $filters: ProductFilters
    $first: Int
    $last: Int
    $revert: Boolean
    $sortBy: ProductSortByKey
  ) {
    total: productsCount(filters: $filters)
    result: products(
      after: $after
      before: $before
      filters: $filters
      first: $first
      last: $last
      revert: $revert
      sortBy: $sortBy
    ) {
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }

      nodes {
        ...ProductFragment
      }

      edges {
        cursor
        node {
          ...ProductFragment
        }
      }
    }
  }
`

export const QUERY_GET_PRODUCT = gql`
  ${fragments.Product}
  query GetProduct($id: ID!) {
    product(id: $id) {
      ...ProductFragment
    }
  }
`

export const MUTATION_CREATE_PRODUCT = gql`
  ${fragments.Product}
  mutation CreateProduct($data: CreateProductData!) {
    createProduct(data: $data) {
      ...ProductFragment
    }
  }
`

export const MUTATION_UPDATE_PRODUCT = gql`
  ${fragments.Product}
  mutation UpdateProduct($id: ID!, $data: UpdateProductData!) {
    updateProduct(id: $id, data: $data) {
      ...ProductFragment
    }
  }
`

export const MUTATION_DELETE_PRODUCT = gql`
  mutation DeleteProduct($id: ID!) {
    deleteProduct(id: $id)
  }
`

export const QUERY_GET_RELEASE_ENTRIES = gql`
  ${fragments.ReleaseEntry}
  query GetReleaseEntries(
    $after: String
    $before: String
    $filters: ReleaseEntryFilters
    $first: Int
    $last: Int
    $revert: Boolean
    $sortBy: ReleaseEntrySortByKey
  ) {
    total: releaseEntriesCount(filters: $filters)
    result: releaseEntries(
      after: $after
      before: $before
      filters: $filters
      first: $first
      last: $last
      revert: $revert
      sortBy: $sortBy
    ) {
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }

      nodes {
        ...ReleaseEntryFragment
      }

      edges {
        cursor
        node {
          ...ReleaseEntryFragment
        }
      }
    }
  }
`

export const MUTATION_UPDATE_RELEASE_ENTRY_STATUS = gql`
  ${fragments.ReleaseEntry}
  mutation UpdateReleaseEntryStatus($id: ID!, $releaseId: ID!, $status: ReleaseEntryStatus!) {
    updateReleaseEntryStatus(id: $id, releaseId: $releaseId, status: $status) {
      ...ReleaseEntryFragment
    }
  }
`

export const QUERY_GET_RELEASE_STATS = gql`
  query GetReleaseStats($id: ID!) {
    releaseStats(id: $id) {
      total
      waiting
      selectedForConfirmation
      selectedForRejection
      confirmed
      rejected
      perCombination
    }
  }
`

export const MUTATION_RELEASE_START_SELECTION = gql`
  ${fragments.Release}
  mutation ReleaseStartSelection($id: ID!, $selectionData: JSON!) {
    releaseStartSelection(id: $id, selectionData: $selectionData) {
      ...ReleaseFragment
    }
  }
`

export const MUTATION_RELEASE_REQUEST_DRAW = gql`
  mutation ReleaseRequestDraw($id: ID!) {
    releaseRequestDraw(id: $id)
  }
`

export const MUTATION_REQUEST_RELEASE_FULFILLMENT = gql`
  mutation ReleaseRequestFulfillment($id: ID!) {
    releaseRequestFullfillment(id: $id)
  }
`

export const SUBSCRIPTION_RELEASE_DRAW_QUEUE_REPORT = gql`
  subscription ReleaseDrawQueueReport($id: ID!) {
    releaseDrawQueueReport(releaseId: $id) {
      status
      message
      progress
    }
  }
`

export const QUERY_GET_RELEASE_DRAW_QUEUE_STATUS = gql`
  query GetReleaseDrawQueueStatus($id: ID!) {
    releaseDrawQueueStatus(id: $id) {
      status
      message
      progress
    }
  }
`

export const QUERY_GET_SUBSCRIPTIONS_AUTH_KEY = gql`
  query GetSubscriptionsAuthKey {
    getSubscriptionsAuthKey
  }
`

export const QUERY_GET_RELEASE_FULLFILLMENT_QUEUE_STATUS = gql`
  query GetReleaseFullfillmentQueueStatus($id: ID!) {
    releaseFullfillmentQueueStatus(id: $id) {
      status
      message
      progress
    }
  }
`

export const SUBSCRIPTION_RELEASE_FULLFILLMENT_QUEUE_REPORT = gql`
  subscription ReleaseFullfillmentQueueReport($id: ID!) {
    releaseFullfillmentQueueReport(releaseId: $id) {
      status
      message
      progress
    }
  }
`
