import * as React from 'react'
import { HyperThemeEditor, ThemeEditorProvider } from '@hypertheme-editor-pro/chakra-ui'
import { Admin, defaultI18n, en, getDefaultI18nOptions, RequireAuth, Resource, Route } from 'chakra-admin'
import { theme } from './theme'
import { ChakraProvider } from '@chakra-ui/react'
import { MashupAuthProvider } from './AuthProvider'
import { client } from './apolloClient'
import { it } from './it'
import { RecoilRoot } from 'recoil'
import './App.css'
import { Global } from '@emotion/react'
import { RouteLayout } from './components/layout/merchant/RouteLayout'
import { Login } from './components/login/Login'
import ProductResource from './resources/Product'
import ReleaseResource from './resources/Release'
import AttendanceResource from './resources/Attendance'
import { MashupStrategy } from './strategies/MashupStrategy'
import { Settings } from './pages/settings/Settings'

import '@fontsource/quicksand'
import '@fontsource/inter'
import { BootstrapPage } from './pages/BootstrapPage'
import { AdminLogin } from './pages/AdminLogin'
import { StripeConnect } from './pages/StripeConnect'

const appIdRouteMiddleware = () => {
  let appId = window?.localStorage?.getItem('appId')

  if (!appId) {
    return '/'
  }

  return true
}

export const App = () => {
  const isWindows = React.useMemo(() => {
    return navigator.userAgent.indexOf('Windows') !== -1
  }, [])

  return (
    <RecoilRoot>
      {isWindows && (
        <Global
          styles={{
            'div::-webkit-scrollbar': {
              width: '10px',
              height: '10px',
            },
            'div::-webkit-scrollbar-track': {
              background: 'transparent',
            },

            'div::-webkit-scrollbar-thumb': {
              backgroundColor: 'var(--chakra-colors-blackAlpha-200)',
              borderRadius: '20px',
            },

            'div::-webkit-scrollbar-thumb:hover': {
              backgroundColor: 'var(--chakra-colors-blackAlpha-400)',
            },
          }}
        />
      )}
      <ChakraProvider theme={theme} portalZIndex={10}>
        {/* <ThemeEditorProvider> */}
        <Admin
          client={client}
          loginComponent={<Login />}
          strategy={MashupStrategy}
          authProvider={MashupAuthProvider}
          layoutComponent={<RouteLayout />}
          routeMiddleware={appIdRouteMiddleware}
          // modalComponent={<ModalRouteLayout />}
          i18nProviderProps={{
            i18n: defaultI18n,
            options: {
              ...getDefaultI18nOptions({ en, it }),
              fallbackLng: 'it',
            },
          }}
        >
          <Route
            index
            element={
              <RequireAuth>
                <BootstrapPage />
              </RequireAuth>
            }
          />
          <Route path="admin-login" element={<AdminLogin />} />
          <Route path="stripe-connect/*" element={<StripeConnect />} />
          <Resource {...(ProductResource as any)} />
          <Resource {...(ReleaseResource as any)} />
          <Resource {...(AttendanceResource as any)} />
          <Route
            useAdminLayout
            path="settings/*"
            element={
              <RequireAuth>
                <Settings />
              </RequireAuth>
            }
          />
          <Route path="*" element={<div>404</div>} />
        </Admin>
        {/* <HyperThemeEditor pos="fixed" bottom={4} right={4} />
        </ThemeEditorProvider> */}
      </ChakraProvider>
    </RecoilRoot>
  )
}
