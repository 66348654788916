import { FC, forwardRef } from 'react'
import {
  Box,
  BoxProps,
  Heading,
  HeadingProps,
  Input,
  InputProps,
  ResponsiveValue,
  Skeleton,
  SkeletonProps,
  SkeletonText,
  Stack,
  StackProps,
  Text,
  TextProps,
} from '@chakra-ui/react'

type LoadingProps = Pick<SkeletonProps, 'isLoaded'>
type TextLoadingProps = LoadingProps & { skeletonNoOfLines?: ResponsiveValue<number> }

export const Section: FC<BoxProps> = (props) => {
  return <Box p={4} m={0} borderRadius="xl" borderColor="gray.200" borderWidth="thin" {...props} />
}

export const SectionTitle: FC<HeadingProps & LoadingProps> = ({ isLoaded = true, ...props }) => {
  return (
    <Skeleton width="200px" isLoaded={isLoaded} mb={2}>
      <Heading as="h2" size="md" fontWeight="semibold" {...props} />
    </Skeleton>
  )
}

export const SectionDescription: FC<TextProps & TextLoadingProps> = ({ isLoaded = true, noOfLines = 2, ...props }) => {
  return (
    <SkeletonText skeletonHeight={6} isLoaded={isLoaded} noOfLines={noOfLines}>
      <Text as="span" fontSize="sm" {...props} />
    </SkeletonText>
  )
}

export const SectionInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  return <Input ref={ref} borderRadius="md" size="sm" width="300px" mt={4} mb={2} {...props} />
})

export const SectionFooter: FC<StackProps & { description?: string }> = ({ description, ...props }) => {
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={3}
      bg="gray.50"
      mt={2}
      px={3}
      py={3}
      borderBottomRadius="xl"
      mx={-4}
      mb={-4}
      {...props}
    />
  )
}

export const SectionFooterDescription: FC<TextProps> = ({ noOfLines = 2, ...props }) => {
  return <Text a as="span" fontSize="sm" color="gray.500" flex="1" {...props} />
}
