import { ElapsedPeriod } from './components/filters/DateRangeFilter'

export const it = {
  translation: {
    ca: {
      action: {
        create: 'Nuovo %{name}',
        close: 'Chiudi',
        edit: 'Modifica',
        show: 'Visualizza',
        delete: 'Elimina',
        cancel: 'Annulla',
        confirm_delete: 'Elimina %{name}',
        submit: 'Invia',
        logout: 'Logout',
      },
      page: {
        list: '%{name}',
        create: 'Nuovo %{name}',
        edit: 'Modifica %{name} #%{id}',
        show: '%{name} #%{id}',
      },
      pagination: {
        page_info: '<strong>%{from}-%{to}</strong> di <strong>%{total}</strong>',
        many: 'tanti',
        back_to_top: "Torna all'inizio",
      },
      message: {
        delete_title: 'Elimina %{name} #%{id}',
        delete_content: 'Sei sicuro di voler eliminare questa risorsa?',
        required_field: 'Questo campo è obbligatorio',
        // eslint-disable-next-line prettier/prettier
        delete_error: "Errore nell' eliminazione",
        loading: 'Caricamento in corso...',
      },
      input: {
        single_image: {
          label: 'Seleziona o rilascia immagine',
          // eslint-disable-next-line prettier/prettier
          while_dragging: "Rilascia qui l'immagine",
        },
      },
    },
    components: {
      ProjectBoard: {
        laneMenuAriaLabel: 'Opzioni Lista',
        deleteLane: 'Elimina lista',
        deleteLaneConfirm: 'Sei sicuro di voler eliminare questa lista? Questa azione non è reversibile.',
        // eslint-disable-next-line @typescript-eslint/quotes
        createNewLane: "Aggiungi un'altra lista",
        createLanePlaceholder: 'Inserisci un nome alla lista...',
        confirmCreateLane: 'Crea lista',
        addCardLink: 'Aggiungi una scheda',
        confirmAddCard: 'Aggiungi scheda',
        cardTitlePlaceholder: 'Inserisci un titolo per questa scheda...',
      },
      DateRangeFilter: {
        periods: {
          [ElapsedPeriod.today]: 'Oggi',
          [ElapsedPeriod.yesterday]: 'Ieri',
          [ElapsedPeriod.tomorrow]: 'Domani',
          [ElapsedPeriod.this_month]: 'Questo mese',
          [ElapsedPeriod.next_month]: 'Il prossimo mese',
          [ElapsedPeriod.this_week]: 'Questa settimana',
          [ElapsedPeriod.previous_week]: 'La settimana scorsa',
          [ElapsedPeriod.next_week]: 'La settimana prossima',
          [ElapsedPeriod.this_quarter]: 'Questo trimestre',
          [ElapsedPeriod.next_quarter]: 'Il prossimo trimestre',
          [ElapsedPeriod.this_halfyear]: 'Questo semestre',
          [ElapsedPeriod.next_halfyear]: 'Il prossimo semestre',
          [ElapsedPeriod.this_year]: "Quest'anno",
          [ElapsedPeriod.next_year]: "L'anno prossimo",
        },
      },
    },
    resources: {
      Admin: {
        label: 'Amministratore |||| Amministratori',
        fields: {
          firstName: 'Nome',
          lastName: 'Cognome',
          email: 'Email',
          password: 'Password',
        },
        placeholders: {
          firstName: 'Insersci il nome',
          lastName: 'Inserisci il cognome',
          email: "Inserisci l'email",
          password: 'Inserisci la password',
        },
      },
      User: {
        label: 'Utente |||| Utenti',
        fields: {
          id: 'ID',
          refId: 'Ref ID',
          appId: 'App ID',
          userChannels: 'Canali',
          updatedAt: 'Ultimo Aggiornamento',
        },
        placeholders: {
          refId: 'Ref ID',
          appId: 'Seleziona App',
        },
      },

      App: {
        label: 'Applicazione |||| Applicazioni',
        fields: {
          name: 'Nome',
          active: 'Attivo',
        },
        placeholders: {
          name: "Inserisci il nome dell'app",
          active: 'Questa applicazione può inviare notifiche',
        },
      },
      NotificationType: {
        label: 'Tipo di Notifica |||| Tipi di Notifiche',
        fields: {
          name: 'Nome',
          slug: 'Slug',
          description: 'Descrizione',
          active: 'Attivo',
          app: {
            name: 'Applicazione',
          },
          notificationCenterEnabled: 'Centro notifiche attivo',
          emailEnabled: 'Email attiva',
          smsEnabled: 'SMS attivo',
          webPushEnabled: 'Webpush attivo',
        },
        placeholders: {
          name: 'Inserisci un nome...',
          description: 'Una descrizione...',
          slug: 'Inserisci uno slug...',
        },
      },
    },
  },
}
