import React, { FC } from 'react'
import { MUTATION_UPDATE_PRODUCT, QUERY_GET_PRODUCT } from '../../queries'
import { BaseForm } from '../../components/forms/BaseForm'
import { ProductForm } from './ProductForm'
import { Edit } from 'chakra-admin'
import { FormPageLayout } from '../../components/details/FormPageLayout'
import { PageTitle } from '../../components/details/PageTitle'

export const ProductEdit: FC = (props) => {
  return (
    <Edit
      query={QUERY_GET_PRODUCT}
      mutation={MUTATION_UPDATE_PRODUCT}
      layout={<FormPageLayout />}
      title={(<PageTitle />) as any}
      {...props}
    >
      <BaseForm>
        <ProductForm />
      </BaseForm>
    </Edit>
  )
}
