import { Navigate, Route, Routes } from 'react-router-dom'

function ReturnPage() {
  return <Navigate to="/" />
}

export function StripeConnect() {
  return (
    <Routes>
      <Route path="refresh" element={<ReturnPage />} />
      <Route path="return" element={<ReturnPage />} />
    </Routes>
  )
}
