import { FC } from 'react'
import { MUTATION_CREATE_PRODUCT } from '../../queries'
import { BaseForm } from '../../components/forms/BaseForm'
import { ProductForm } from './ProductForm'
import { Create } from 'chakra-admin'
import { FormPageLayout } from '../../components/details/FormPageLayout'
import { PageTitle } from '../../components/details/PageTitle'

export const ProductCreate: FC = (props) => {
  return (
    <Create mutation={MUTATION_CREATE_PRODUCT} layout={<FormPageLayout />} title={(<PageTitle />) as any} {...props}>
      <BaseForm
        defaultValues={{
          taxRate: 22,
        }}
      >
        <ProductForm />
      </BaseForm>
    </Create>
  )
}
