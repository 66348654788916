import { Button, ButtonProps } from '@chakra-ui/react'
import { forwardRef } from 'react'

export const SaveSettingButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  return <Button variant="solid" colorScheme="primary" size="sm" ref={ref} {...props} />
})

export const CancelSettingButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  return <Button variant="ghost" size="sm" ref={ref} {...props} />
})
