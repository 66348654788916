import { FC } from 'react'
import { MUTATION_CREATE_RELEASE } from '../../queries'
import { BaseForm } from '../../components/forms/BaseForm'
import { ReleaseForm } from './ReleaseForm'
import { Create } from 'chakra-admin'
import { FormPageLayout } from '../../components/details/FormPageLayout'
import { PageTitle } from '../../components/details/PageTitle'

export const ReleaseCreate: FC = (props) => {
  return (
    <Create mutation={MUTATION_CREATE_RELEASE} layout={<FormPageLayout />} title={(<PageTitle />) as any} {...props}>
      <BaseForm>
        <ReleaseForm {...props} />
      </BaseForm>
    </Create>
  )
}
