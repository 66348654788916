import { OperationVariables } from '@apollo/client'
import { CreateStrategy as DefaultCreateStrategy } from 'chakra-admin'

export class CreateStrategy implements DefaultCreateStrategy {
  getMutationVariables(values: Record<string, any>): OperationVariables {
    switch (values.__typename) {
      case 'Release': {
        const { __typename, dateRange, ...data } = values as any
        return {
          data: {
            ...data,
            name: 'Release',
            startDate: new Date(dateRange[0]).toISOString(),
            endDate: new Date(dateRange[1]).toISOString(),
          },
        }
      }
      case 'Product': {
        const { __typename, taxRate: taxRateOrigin, ...data } = values as any

        let taxRate = taxRateOrigin

        if (typeof taxRate === 'string') {
          try {
            taxRate = parseFloat(taxRate)
          } catch (e) {
            taxRate = 0
          }
        }

        return {
          data: {
            ...data,
            taxRate,
          },
        }
      }
      default: {
        const { __typename, ...data } = values as any
        return {
          data,
        }
      }
    }
  }
}
