import React, { FC, useMemo } from 'react'
import { Heading, Box, Skeleton, Divider } from '@chakra-ui/react'
import { PageTitleProps, useTranslate } from 'chakra-admin'

export const PageTitle: FC<PageTitleProps & { customLabel?: (record: any) => string }> = ({
  label,
  renderingInModal,
  loading,
  resource,
  data,
  error,
  record,
  icon,
  customLabel,
  ...rest
}) => {
  const t = useTranslate()
  const title = useMemo(() => {
    if (customLabel) {
      return customLabel(record)
    }

    return label
  }, [customLabel, label, record])

  return (
    <Skeleton isLoaded={!loading}>
      <Box mb={8} px={4}>
        <Heading as="h1" size="xl" fontWeight="semibold" mt={10} mb={8}>
          {title || t('ca.message.loading')}
        </Heading>
        <Divider />
      </Box>
    </Skeleton>
  )
}
