import { useCallback } from 'react'
import {
  chakra,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Button,
  Box,
  Input,
  Heading,
  Alert,
  AlertIcon,
  VStack,
} from '@chakra-ui/react'
import { useForm, Controller, useFormContext, FormProvider } from 'react-hook-form'
import { AppIntegrationTypeRadio } from './AppIntegrationTypeRadio'
import { useCreateAppMutation, gql_CreateAppMutation } from '../../graphql'
import { ApolloError } from '@apollo/client'

const validateUrl = (value: string): boolean | string => {
  if (!value.startsWith('http')) {
    return 'URL must start with http:// or https://'
  }

  try {
    const url = new URL(value)
    if (!url.hostname) {
      return 'URL must be a valid URL'
    }

    return true
  } catch (e) {
    return 'URL must be a valid URL'
  }
}

type AppCreateProps = {
  onAppCreated: (app: gql_CreateAppMutation['createApp']) => void
}

type IntegrationType = 'SHOPIFY' | 'CUSTOM'

type AppData = {
  name: string
  url: string
  integrationType: IntegrationType
  shopifyAdminToken?: string
  shopifyApiKey?: string
  shopifyApiSecret?: string
}

export function AppOnboardingCreate({ onAppCreated }: AppCreateProps) {
  const ctx = useForm<AppData>()
  const [createApp, { loading, error }] = useCreateAppMutation()

  const onSubmit = useCallback(
    async (data: AppData) => {
      if (
        data.integrationType === 'SHOPIFY' &&
        (!data.shopifyAdminToken || !data.shopifyApiKey || !data.shopifyApiSecret)
      ) {
        if (!data.shopifyAdminToken) {
          ctx.setError('shopifyAdminToken', {
            message: 'Shopify Admin Token is required',
            type: 'required',
          })
        }

        if (!data.shopifyApiKey) {
          ctx.setError('shopifyApiKey', {
            message: 'Shopify API Key is required',
            type: 'required',
          })
        }

        if (!data.shopifyApiSecret) {
          ctx.setError('shopifyApiSecret', {
            message: 'Shopify API Secret is required',
            type: 'required',
          })
        }

        return
      }

      try {
        const result = await createApp({
          variables: {
            data: {
              name: data.name,
              url: data.url,
              // integrationType: data.integrationType as gql_AppIntegrationType,
              integration: {},
            },
          },
        })

        if (result.data?.createApp) {
          onAppCreated(result.data.createApp)
        }
      } catch (e) {
        console.error(e)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createApp, onAppCreated]
  )

  return (
    <FormProvider {...ctx}>
      <chakra.form onSubmit={ctx.handleSubmit(onSubmit)}>
        <AppOnboardingForm loading={loading} error={error} />
      </chakra.form>
    </FormProvider>
  )
}

type AppOnboardingFormProps = {
  loading?: boolean
  error?: ApolloError
}

function AppOnboardingForm({ loading, error }: AppOnboardingFormProps) {
  const {
    register,
    watch,
    control,
    formState: { errors, isDirty, isValid },
  } = useFormContext<AppData>()

  const integrationType = watch('integrationType')

  return (
    <VStack spacing={5} alignItems="flex-start">
      <FormControl isInvalid={!!errors.name}>
        <FormLabel htmlFor="create-app_name">Name</FormLabel>
        <Input id="create-app_name" type="string" {...register('name', { required: 'Name is required' })} />
        {errors.name && <FormErrorMessage isTruncated>{errors.name.message}</FormErrorMessage>}
      </FormControl>

      <FormControl isInvalid={!!errors.url}>
        <FormLabel htmlFor="create-app_url">Website/App URL</FormLabel>
        <Input
          id="create-app_url"
          type="string"
          {...register('url', {
            required: 'URL is required',
            validate: validateUrl,
          })}
        />
        {!errors.url ? (
          <FormHelperText>Enter the URL of your website or app</FormHelperText>
        ) : (
          <FormErrorMessage isTruncated>{errors.url.message}</FormErrorMessage>
        )}
      </FormControl>

      <FormControl isInvalid={!!errors.integrationType}>
        <FormLabel htmlFor="create-app_integrationType">Integration Type</FormLabel>

        <Controller
          name="integrationType"
          control={control}
          defaultValue="CUSTOM"
          render={({ field }) => (
            <AppIntegrationTypeRadio
              {...field}
              defaultValue="CUSTOM"
              onChange={(value) => {
                console.log('value', value)
                field.onChange(value)
              }}
            />
          )}
        />
      </FormControl>
      <pre>{integrationType}</pre>

      {integrationType === 'CUSTOM' && <Box>Custom</Box>}
      {integrationType === 'SHOPIFY' && <AppShopifyIntegration />}

      {error?.message && (
        <Alert status="error">
          <AlertIcon />
          There was an error processing your request
        </Alert>
      )}

      <Button
        colorScheme="primary"
        width="full"
        isLoading={loading}
        type="submit"
        isDisabled={!isDirty || !isValid || loading}
      >
        Create
      </Button>
    </VStack>
  )
}

function AppShopifyIntegration() {
  const {
    register,
    formState: { errors },
  } = useFormContext<AppData>()

  return (
    <>
      <Heading>Shopify Integration Setup</Heading>

      <FormControl isInvalid={!!errors.shopifyAdminToken}>
        <FormLabel htmlFor="create-app_shopifyApiKey">Shopify Admin Access Token</FormLabel>
        <Input id="create-app_shopifyAdminToken" type="string" {...register('shopifyAdminToken')} />
        {errors.shopifyAdminToken && (
          <FormErrorMessage isTruncated>{errors.shopifyAdminToken.message}</FormErrorMessage>
        )}
      </FormControl>
      <FormControl isInvalid={!!errors.shopifyApiKey}>
        <FormLabel htmlFor="create-app_shopifyApiKey">Shopify API Key</FormLabel>
        <Input id="create-app_shopifyApiKey" type="string" {...register('shopifyApiKey')} />
        {errors.shopifyApiKey && <FormErrorMessage isTruncated>{errors.shopifyApiKey.message}</FormErrorMessage>}
      </FormControl>

      <FormControl isInvalid={!!errors.shopifyApiSecret}>
        <FormLabel htmlFor="create-app_shopifyApiSecret">Shopify API Secret</FormLabel>
        <Input id="create-app_shopifyApiSecret" type="string" {...register('shopifyApiSecret')} />
        {errors.shopifyApiSecret && <FormErrorMessage isTruncated>{errors.shopifyApiSecret.message}</FormErrorMessage>}
      </FormControl>
    </>
  )
}
