import { Box } from '@chakra-ui/react'
import { Navigate, Route, Routes } from 'react-router-dom'
import {
  SettingsLayout,
  SettingsMenu,
  SettingsMenuGroup,
  SettingsMenuItem,
} from '../../components/settings/settings-layout'
import { AccountSettings } from './AccountSettings'
import { AppGeneralSettings } from './AppGeneralSettings'
import { AppIntegrationsSettings } from './AppIntegrationsSettings'

export function Settings() {
  return (
    <SettingsLayout
      menu={
        <SettingsMenu>
          <SettingsMenuGroup label="Account">
            <SettingsMenuItem to="account">Account</SettingsMenuItem>
          </SettingsMenuGroup>
          <SettingsMenuGroup label="App">
            <SettingsMenuItem to="app-general">General</SettingsMenuItem>
            <SettingsMenuItem to="app-integration">Integrations</SettingsMenuItem>
          </SettingsMenuGroup>
          <SettingsMenuGroup label="Merchant">
            <SettingsMenuItem to="merchant-general">General</SettingsMenuItem>
            <SettingsMenuItem to="staff-members">Staff Members</SettingsMenuItem>
          </SettingsMenuGroup>
        </SettingsMenu>
      }
    >
      <Routes>
        <Route path="account" element={<AccountSettings />} />
        <Route path="app-general" element={<AppGeneralSettings />} />
        <Route path="app-integration" element={<AppIntegrationsSettings />} />
        <Route path="merchant-general" element={<Box>Impostazioni Negozio/Azienda</Box>} />
        <Route path="staff-members" element={<Box>Impostazioni Staff Member</Box>} />
        <Route index element={<Navigate to="account" replace />} />
      </Routes>
    </SettingsLayout>
  )
}
