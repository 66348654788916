/**
 *  Calculate the cartesian product of two or more arrays.
 *
 * @example
 * cartesian([1, 2], [3, 4]) // [[1, 3], [1, 4], [2, 3], [2, 4]]
 *
 * @param ...a
 * @returns
 */
export const cartesian = (...a: any[]) => a.reduce((a, b) => a.flatMap((d: any) => b.map((e: any) => [d, e].flat())))
