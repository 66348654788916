import { ApolloClient, InMemoryCache, split } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from '@apollo/client/link/context'
import { getMainDefinition } from '@apollo/client/utilities'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { subAuthToken } from './subscription-auth-token'

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_URI || '/merchant/graphql',
  credentials: 'include',
  headers: {
    'X-Requested-With': 'fetch',
  },
})

const appIdLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const appId = localStorage.getItem('appId')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'X-Merchant-App-Id': appId || '',
    },
  }
})

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_SUBSCRIPTION_URI || 'ws://localhost:4030/merchant/subscriptions',
    shouldRetry: (options) => true,
    retryWait: (retries) => new Promise((resolve) => setTimeout(resolve, retries * 1000)),
    retryAttempts: Infinity,
    connectionParams: () => {
      return {
        Authorization: `${subAuthToken.u}:${subAuthToken.s}`,
      }
    },
  }),
)

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  wsLink,
  appIdLink.concat(httpLink),
)

export const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
})
