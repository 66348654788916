import { Navigate } from 'react-router-dom'
import { Bootstrap } from '../components/bootstrap/Bootstrap'

type BootstrapPageProps = {
  initialPage?: string
}

export function BootstrapPage({ initialPage = '/Release' }: BootstrapPageProps) {
  if (typeof window === 'undefined') {
    return null
  }

  if (localStorage.getItem('appId')) {
    return <Navigate to={initialPage} />
  }

  return <Bootstrap />
}
