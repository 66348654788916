import {
  Box,
  Container,
  Flex,
  Heading,
  Divider,
  VStack,
  ButtonProps,
  Button,
  StackProps,
  HeadingProps,
  Grid,
} from '@chakra-ui/react'
import { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'

export type SettingsMenuItemProps = {
  to: string
} & ButtonProps

export function SettingsMenuItem({ to, ...rest }: SettingsMenuItemProps) {
  const location = useLocation()
  const isActive = useMemo(() => location.pathname.endsWith(to), [location.pathname, to])

  return (
    <Button
      as={Link}
      to={to}
      variant="ghost"
      justifyContent="flex-start"
      _hover={{ bg: 'gray.50' }}
      _active={{ fontWeight: 'bold' }}
      _selected={{ fontWeight: 'bold' }}
      fontWeight="normal"
      // _focus={{ boxShadow: 'none', bg: 'gray.100' }}
      isActive={isActive}
      {...rest}
    />
  )
}

export type SettingsMenuGroupProps = {
  label: string
  children: React.ReactNode
} & Omit<HeadingProps, 'children'>

export function SettingsMenuGroup({ label, children, ...props }: SettingsMenuGroupProps) {
  return (
    <Box pb={4} minWidth="170px" width="170px">
      <Heading as="h2" size="xs" fontWeight="semibold" color="blackAlpha.700" _notFirst={{ mt: 2 }} mb={2} {...props}>
        {label}
      </Heading>

      <VStack alignItems="stretch">{children}</VStack>
    </Box>
  )
}

export type SettingsMenuProps = {} & StackProps

export function SettingsMenu({ ...props }: SettingsMenuProps) {
  return (
    <VStack
      minWidth="170px"
      width="170px"
      spacing={0}
      mr={20}
      alignItems="stretch"
      position="sticky"
      top={10}
      height="fit-content"
      {...props}
    />
  )
}

export type SettingsLayoutProps = {
  children: React.ReactNode
  menu: React.ReactNode
}

export function SettingsLayout({ children, menu }: SettingsLayoutProps) {
  return (
    <Container maxWidth="container.xl" px={0}>
      <Flex flexDir="column" px={8}>
        <Box mb={8}>
          <Heading as="h1" size="xl" fontWeight="semibold" mt={10} mb={8}>
            Settings
          </Heading>
          <Divider />
        </Box>
        <Grid templateColumns="250px 1fr">
          {menu}
          <Box flex={1} pb={10}>
            {children}
          </Box>
        </Grid>
      </Flex>
    </Container>
  )
}
