/* eslint-disable react/jsx-key */
import React, { FC } from 'react'
import {
  DataTableValue as Field,
  GenericMoreMenuButton,
  Input,
  List,
  ListToolbar,
  Filters,
  DataTable,
} from 'chakra-admin'
import { MUTATION_DELETE_RELEASE, QUERY_GET_RELEASES } from '../../queries'
import { gql_Query, gql_Release } from '../../graphql'
import { Box, useBreakpointValue } from '@chakra-ui/react'
import { CreateButton } from '../../components/buttons/CreateButton'

const ReleaseFilters: FC = (props) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  return (
    <Filters {...props}>
      <Input
        source="name"
        label="Nome"
        placeholder="Cerca per nome..."
        alwaysOn={isMobile ? false : true}
        border="none"
        bgColor="transparent"
        _hover={{ bgColor: 'transparent' }}
        borderRadius="xl"
        boxShadow="0px 1px 4px rgba(0, 0, 0, 0.05), 0px 6px 24px rgba(0, 0, 0, 0.04), inset 0px 1px 1px rgba(0, 0, 0, 0.04)"
      />
    </Filters>
  )
}

export const ReleaseList: FC = (props) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  return (
    <List<gql_Query, gql_Release>
      paginationMode="cursor"
      query={QUERY_GET_RELEASES}
      deleteItemMutation={MUTATION_DELETE_RELEASE}
      filtersComponent={<ReleaseFilters />}
      toolbarComponent={
        <ListToolbar>
          <CreateButton label={isMobile ? 'Crea' : true} />
        </ListToolbar>
      }
      {...props}
    >
      <DataTable
        tableProps={{
          size: 'sm',
        }}
        moreMenuComponent={<GenericMoreMenuButton />}
        rowClick="show"
      >
        <Field source="id" />
        <Field source="name" />
        <Field source="productId" />
        <Field source="dropType" />
        <Field
          source="active"
          render={({ record }: any) => {
            return <Box>{record.isPublished ? 'Attivo' : 'Non attivo'}</Box>
          }}
        />
        <Field<gql_Release> source="updatedAt" />
      </DataTable>
    </List>
  )
}
