import {
  Box,
  useRadio,
  useRadioGroup,
  UseRadioGroupProps,
  UseRadioProps,
  Text,
  StackProps,
  Heading,
  Stack,
} from '@chakra-ui/react'

type ReleaseTypeCardProps = {
  title?: string
  description?: string
} & UseRadioProps

export function ReleaseTypeCard({ title, description, ...props }: ReleaseTypeCardProps) {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as="label">
      <input {...input} />

      <Box
        {...checkbox}
        w="170px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        border="1px solid"
        borderColor="gray.100"
        p={5}
        borderRadius="lg"
        h="100%"
        cursor={props.isDisabled ? 'not-allowed' : 'pointer'}
        _hover={{
          bg: 'gray.50',
          borderColor: 'blackAlpha.500',
        }}
        _checked={{
          borderColor: 'transparent',
          outline: '2px solid',
          outlineColor: 'primary.500',
          h2: {
            color: 'primary.800',
          },
        }}
        opacity={props.isDisabled ? 0.4 : 1}
        _focus={{
          boxShadow: 'outline',
        }}
      >
        <Heading as="h2" size="xs" fontWeight="semibold" mb={2}>
          {title}
        </Heading>
        <Text as="span" mt={3} fontSize="xs" textAlign="center">
          {description}
        </Text>
      </Box>
    </Box>
  )
}

type ReleaseTypeRadioProps = {} & UseRadioGroupProps & StackProps

export function ReleaseTypeRadio({
  value,
  defaultValue,
  onChange,
  isDisabled,
  isFocusable,
  name,
  isNative,
  ...rest
}: ReleaseTypeRadioProps) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    onChange,
    isDisabled,
    isFocusable,
    isNative,
    value,
  })

  const group = getRootProps()

  return (
    <Stack direction="row" spacing={4} my={4} {...rest} {...group}>
      <ReleaseTypeCard
        title="Online"
        description="Draw, payments and delivery are done online"
        {...getRadioProps({ value: 'ONLINE' })}
      />
      <ReleaseTypeCard
        title="In-store"
        description="Draw are done online, payments and delivery are done in-store"
        {...getRadioProps({ value: 'IN_STORE' })}
      />
      <ReleaseTypeCard
        title="Physical"
        description="Draw, payments and delivery are done in-store, no online interaction"
        {...getRadioProps({ value: 'PHYSICAL' })}
      />
    </Stack>
  )
}
