import { Box, BoxProps, Divider, Flex, FlexProps, Heading } from '@chakra-ui/react'

export type FormSectionProps = {
  children?: React.ReactNode
  title?: string
  description?: string
} & FlexProps

export function FormSection({ children, title, description, ...props }: FormSectionProps) {
  return (
    <Box mb={8}>
      <Flex justifyContent="space-between" mb={6} {...props}>
        <Box flex="1" maxWidth="440px">
          <Heading as="h2" size="sm" fontWeight="semibold" mb={2}>
            {title}
          </Heading>
          <Heading as="h3" size="xs" fontWeight="normal" color="blackAlpha.700">
            {description}
          </Heading>
        </Box>

        <Box display="flex" justifyContent="flex-end" flex="1">
          {children}
        </Box>
      </Flex>
      <Divider />
    </Box>
  )
}
