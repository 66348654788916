import React, { useEffect, useMemo } from 'react'
import { Badge, Box, ButtonProps, HStack, RadioProps, useRadio, useRadioGroup, useTab } from '@chakra-ui/react'
import { gql_Release, gql_ReleaseStats, useGetReleaseStatsQuery } from '../../../graphql'
import { useVersionStateValue } from 'chakra-admin'

export type StatusRadioProps = {
  count: number
} & RadioProps

export const StatusRadio = React.forwardRef<HTMLElement, StatusRadioProps>(({ count, colorScheme, ...props }, ref) => {
  const { getInputProps, getRadioProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getRadioProps()

  return (
    <Box as="label">
      <input {...input} />

      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        borderColor="gray.200"
        color="gray.700"
        _checked={{
          borderColor: `var(--chakra-colors-${colorScheme}-600)`,
          color: `var(--chakra-colors-${colorScheme}-600)`,
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        px={4}
        py={2}
      >
        {props.children}

        <Badge variant="solid" colorScheme={colorScheme} ml="2">
          {count}
        </Badge>
      </Box>
    </Box>
  )
})

export type EntryStatusFilterProps = {
  name: string
  options?: {
    label: string
    value: string
    colorScheme?: ButtonProps['colorScheme']
  }[]
  onChange: (value: string) => void
  value: string
  record?: Partial<gql_Release>
  releaseStats?: Partial<gql_ReleaseStats>
} & ButtonProps

export function EntryStatusFilter({
  name,
  options = [
    {
      label: 'Tutti',
      value: 'all',
      colorScheme: 'gray',
    },
    {
      label: 'In Attesa',
      value: 'WAITING',
      colorScheme: 'gray',
    },
    {
      label: 'Selezionati per la vincita',
      value: 'SELECTED_FOR_CONFIRMATION',
      colorScheme: 'blue',
    },
    {
      label: 'Selezionati per il rifiuto',
      value: 'SELECTED_FOR_REJECTION',
      colorScheme: 'red',
    },
    {
      label: 'Confermati',
      value: 'CONFIRMED',
      colorScheme: 'green',
    },
    {
      label: 'Rifiutati',
      value: 'REJECTED',
      colorScheme: 'red',
    },
  ],
  onChange,
  value,
  record,
  releaseStats,
}: EntryStatusFilterProps) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    value,
    onChange,
  })

  const optionsWithCount = useMemo(() => {
    if (!releaseStats) {
      return options
    }

    return options.map((option) => {
      return {
        ...option,
        count: (releaseStats as any)?.[mapOptionValueToStatKey(option.value)] || 0,
      }
    })
  }, [options, releaseStats])

  const group = getRootProps()

  return (
    <HStack {...group} mx={4}>
      {optionsWithCount.map((option) => {
        const radio = getRadioProps({ value: option.value })
        return (
          <StatusRadio
            key={option.value}
            {...radio}
            colorScheme={option.colorScheme}
            count={(option as any)?.count || 0}
          >
            {option.label}
          </StatusRadio>
        )
      })}
    </HStack>
  )
}

function mapOptionValueToStatKey(value: string) {
  switch (value) {
    case 'all':
      return 'total'
    case 'WAITING':
      return 'waiting'
    case 'SELECTED_FOR_CONFIRMATION':
      return 'selectedForConfirmation'
    case 'SELECTED_FOR_REJECTION':
      return 'selectedForRejection'
    case 'CONFIRMED':
      return 'confirmed'
    case 'REJECTED':
      return 'rejected'
    default:
      return 'waiting'
  }
}
