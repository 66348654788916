import { chakra, FormControl, FormErrorMessage, HStack, useToast } from '@chakra-ui/react'
import { useAuthProvider } from 'chakra-admin'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useUpdateStaffMemberMePasswordMutation } from '../../../graphql'
import {
  Section,
  SectionTitle,
  SectionDescription,
  SectionInput,
  SectionFooter,
  SectionFooterDescription,
} from '../setting-section'
import { CancelSettingButton, SaveSettingButton } from '../settings-buttons'

type FormData = {
  oldPassword: string
  newPassword: string
}

export type PasswordSettingProps = {}

export function PasswordSetting(props: PasswordSettingProps) {
  const authProvider = useAuthProvider()
  const navigate = useNavigate()
  const [update, { loading: saving }] = useUpdateStaffMemberMePasswordMutation()
  const toast = useToast()

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, errors },
  } = useForm<FormData>()

  const handleReset = useCallback(() => {
    reset()
  }, [reset])

  const onSubmit = useCallback(
    async (data: FormData) => {
      let result = null

      try {
        result = await update({
          variables: {
            oldPassword: data.oldPassword,
            password: data.newPassword,
          },
        })
      } catch (e) {
        toast({
          title: 'Error during password change',
          description: `${(e as any).message}`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }

      if (result?.data?.updateStaffMemberMePassword?.id) {
        try {
          await authProvider?.logout()
        } catch (e) {
        } finally {
          navigate('/', { replace: true })
          navigate(0)
        }
      }
    },
    [authProvider, navigate, toast, update]
  )

  return (
    <Section w="100%" mt={0}>
      <chakra.form onSubmit={handleSubmit(onSubmit)}>
        <SectionTitle>Change Password</SectionTitle>
        <SectionDescription>
          You can change your password here.
          <br />
          You will be logged out after saving this change. You will need to sign in again with your new password.
        </SectionDescription>
        <HStack spacing={5} mt={5} alignItems="flex-start">
          <FormControl width="auto" isInvalid={!!errors?.oldPassword}>
            <SectionInput
              type="password"
              isDisabled={saving}
              {...register('oldPassword', {
                required: 'Old password is required',
              })}
              placeholder="Your old password"
            />
            {!!errors.oldPassword && <FormErrorMessage pb={5}>{errors.oldPassword.message}</FormErrorMessage>}
          </FormControl>

          <FormControl width="auto" isInvalid={!!errors?.newPassword}>
            <SectionInput
              type="password"
              isDisabled={saving}
              {...register('newPassword', {
                required: 'New password is required',
              })}
              placeholder="Your new password"
            />
            {!!errors.newPassword && <FormErrorMessage pb={5}>{errors.newPassword.message}</FormErrorMessage>}
          </FormControl>
        </HStack>

        <SectionFooter>
          <SectionFooterDescription>
            Please note that you will be logged out after saving this change. You will need to sign in again.
          </SectionFooterDescription>
          {isDirty && <CancelSettingButton onClick={handleReset}>Cancel</CancelSettingButton>}
          <SaveSettingButton type="submit" isDisabled={!isDirty || saving} isLoading={saving}>
            Save
          </SaveSettingButton>
        </SectionFooter>
      </chakra.form>
    </Section>
  )
}
