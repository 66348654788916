import {
  Box,
  HStack,
  Icon,
  useRadio,
  useRadioGroup,
  UseRadioGroupProps,
  UseRadioProps,
  Text,
  StackProps,
} from '@chakra-ui/react'
import { BiCodeBlock } from 'react-icons/bi'
import { FaShopify } from 'react-icons/fa'

type InteractionTypeCardProps = {
  label?: string
  icon?: React.ReactNode
} & UseRadioProps

export function InteractionTypeCard({ label, icon, ...props }: InteractionTypeCardProps) {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as="label">
      <input {...input} />

      <Box
        {...checkbox}
        w="165px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgColor="gray.100"
        flexDirection="column"
        boxShadow="md"
        p={5}
        borderRadius="lg"
        cursor={props.isDisabled ? 'not-allowed' : 'pointer'}
        _hover={{
          bg: 'primary.100',
        }}
        _checked={{
          bg: 'primary.500',
          color: 'white',
          borderColor: 'primary.500',
        }}
        opacity={props.isDisabled ? 0.4 : 1}
        _focus={{
          boxShadow: 'outline',
        }}
      >
        {icon}
        <Text as="span" mt={3} fontSize="lg" fontWeight="bold">
          {label || props.value}
        </Text>
      </Box>
    </Box>
  )
}

type AppIntegrationTypeRadioProps = {} & UseRadioGroupProps & StackProps

export function AppIntegrationTypeRadio({
  value,
  defaultValue,
  onChange,
  isDisabled,
  isFocusable,
  name,
  isNative,
  ...rest
}: AppIntegrationTypeRadioProps) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    onChange,
    isDisabled,
    isFocusable,
    isNative,
    value,
  })

  const group = getRootProps()

  return (
    <HStack spacing={4} my={4} {...rest} {...group}>
      <InteractionTypeCard
        label="Custom"
        icon={<Icon fontSize="6xl" as={BiCodeBlock} />}
        {...getRadioProps({ value: 'CUSTOM' })}
      />
      <InteractionTypeCard
        label="Shopify"
        icon={<Icon fontSize="6xl" as={FaShopify} />}
        {...getRadioProps({ value: 'SHOPIFY' })}
      />
    </HStack>
  )
}
