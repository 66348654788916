import { Button, chakra, FormControl, FormErrorMessage, FormHelperText, useToast } from '@chakra-ui/react'
import { useAuthProvider } from 'chakra-admin'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useGetStaffMemberMeQuery, useUpdateStaffMemberMeEmailMutation } from '../../../graphql'
import {
  Section,
  SectionTitle,
  SectionDescription,
  SectionInput,
  SectionFooter,
  SectionFooterDescription,
} from '../setting-section'
import { CancelSettingButton, SaveSettingButton } from '../settings-buttons'

type FormData = {
  email: string
}

function EmailFormSkeleton() {
  return (
    <Section w="100%" mt={0}>
      <SectionTitle isLoaded={false}>Your Email</SectionTitle>
      <SectionDescription isLoaded={false} noOfLines={1}>
        This is the email address you use to sign in to your account.
      </SectionDescription>
      <FormControl width="auto">
        <SectionInput isDisabled placeholder="Email" />
      </FormControl>
      <SectionFooter>
        <Button colorScheme="primary" size="sm" isDisabled>
          Save
        </Button>
      </SectionFooter>
    </Section>
  )
}

type EmailSettingFormProps = {
  defaultValues: FormData
}

function EmailSettingForm({ defaultValues }: EmailSettingFormProps) {
  const authProvider = useAuthProvider()
  const navigate = useNavigate()
  const [update, { loading: saving }] = useUpdateStaffMemberMeEmailMutation()
  const toast = useToast()

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, errors },
  } = useForm<FormData>({
    defaultValues,
  })

  const handleReset = useCallback(() => {
    reset()
  }, [reset])

  const onSubmit = useCallback(
    async (data: FormData) => {
      let result = null
      try {
        result = await update({
          variables: {
            email: data.email,
          },
        })
      } catch (e) {
        toast({
          title: 'Error updating email, Please try again later.',
          description: `${(e as any).message}.`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }

      try {
        if (!result?.errors) {
          await authProvider?.logout()
          navigate('/', { replace: true })
          navigate(0)
        }
      } catch (e) {}
    },
    [authProvider, navigate, toast, update]
  )

  return (
    <Section w="100%" mt={0}>
      <chakra.form onSubmit={handleSubmit(onSubmit)}>
        <SectionTitle>Your Email</SectionTitle>
        <SectionDescription>This is the email address you use to sign in to your account.</SectionDescription>
        <FormControl width="auto" isInvalid={!!errors?.email}>
          <SectionInput
            type="email"
            isDisabled={saving}
            {...register('email', {
              required: 'Email is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Entered value does not match email format',
              },
            })}
            placeholder="Email"
          />
          {!!errors.email && <FormErrorMessage pb={5}>{errors.email.message}</FormErrorMessage>}
        </FormControl>

        <SectionFooter>
          <SectionFooterDescription>
            Please note that you will be logged out after saving this change. You will need to sign in again.
          </SectionFooterDescription>
          {isDirty && <CancelSettingButton onClick={handleReset}>Cancel</CancelSettingButton>}
          <SaveSettingButton type="submit" isDisabled={!isDirty || saving} isLoading={saving}>
            Save
          </SaveSettingButton>
        </SectionFooter>
      </chakra.form>
    </Section>
  )
}

export type EmailSettingProps = {}

export function EmailSetting(props: EmailSettingProps) {
  const { loading, data } = useGetStaffMemberMeQuery()
  const defaultValues = useMemo(() => {
    return {
      email: data?.staffMemberMe?.email ?? '',
    }
  }, [data])

  if (loading) {
    return <EmailFormSkeleton />
  }

  return <EmailSettingForm defaultValues={defaultValues} />
}
