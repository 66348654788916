import { ButtonProps } from '@chakra-ui/react'
import { CreateButton as CACreateButton, ListProps, UseListReturn } from 'chakra-admin'
import { LinkProps } from 'react-router-dom'

type Props = Partial<ListProps> &
  Partial<UseListReturn> & { label?: string | boolean; openAsModal?: boolean } & ButtonProps &
  Partial<LinkProps> &
  Record<string, any>

export function CreateButton(props: Props) {
  return <CACreateButton size="sm" colorScheme="primary" {...props} />
}
