import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, Image, Text, Tooltip } from '@chakra-ui/react'
import { useCallback, useMemo } from 'react'
import { gql_MerchantStripeAccount, useCreateStripeAccountLinkMutation } from '../../graphql'

type StripeAccountRowProps = {
  stripeAccount: Pick<gql_MerchantStripeAccount, 'id' | 'stripeAccount' | 'stripeAccountId'>
}

export function StripeAccountRow({ stripeAccount }: StripeAccountRowProps) {
  const [createAccountLink, { data, loading }] = useCreateStripeAccountLinkMutation({
    onCompleted: (data) => {
      window.location.href = data.createStripeAccountLink
    },
  })

  const created = useMemo(
    () => new Date(stripeAccount.stripeAccount.created * 1000).toLocaleString(),
    [stripeAccount.stripeAccount.created],
  )
  const waiting = useMemo(() => loading || !!data?.createStripeAccountLink, [loading, data])

  const handleCreateAccountLink = useCallback(() => {
    createAccountLink({ variables: { merchantStripeAccountId: stripeAccount.id } })
  }, [createAccountLink, stripeAccount.id])

  return (
    <Box
      display="flex"
      width="100%"
      borderRadius="lg"
      border="1px solid"
      borderColor={!stripeAccount.stripeAccount.details_submitted ? 'transparent' : 'gray.100'}
      // error outline
      outline={!stripeAccount.stripeAccount.details_submitted ? '2px solid' : 'none'}
      outlineColor="red.600"
      p={4}
      alignItems="center"
      height="85px"
    >
      <Image
        src="/images/stripe.svg"
        border="1px solid"
        borderRadius="full"
        width="50px"
        height="50px"
        borderColor="transparent"
        p={2}
        mr={4}
        alt="Stripe Logo"
        bgColor="gray.100"
      />

      <Box display="flex" flexDir="column">
        <Text color="gray.600" fontSize="xs" fontWeight="bold">
          Created at
        </Text>
        <Text color="gray.600" fontSize="xs">
          {created}
        </Text>
      </Box>

      <Box display="flex" flex="1" justifyContent="flex-end">
        {stripeAccount.stripeAccount.details_submitted ? (
          <Tooltip label="Stripe account is ready">
            <CheckCircleIcon boxSize="20px" color="green.500" />
          </Tooltip>
        ) : (
          <Flex alignItems="center">
            <Button
              variant="outline"
              mr={3}
              size="sm"
              onClick={handleCreateAccountLink}
              isDisabled={waiting}
              isLoading={waiting}
            >
              Complete configuration
            </Button>
            <Tooltip label="Stripe account is not ready">
              <WarningIcon boxSize="20px" color="red.500" />
            </Tooltip>
          </Flex>
        )}
      </Box>
    </Box>
  )
}
