import { Stack } from '@chakra-ui/react'

type Props = {
  children: React.ReactNode
}

export function NavMenu({ children }: Props) {
  return (
    <Stack as="ul" ml={2} spacing={2} direction={{ base: 'column', lg: 'row' }} listStyleType="none">
      {children}
    </Stack>
  )
}
