/* eslint-disable import/no-anonymous-default-export */
import { GiConverseShoe } from 'react-icons/gi'
import { ReleaseCreate } from './ReleaseCreate'
import { ReleaseEdit } from './ReleaseEdit'
import { ReleaseList } from './ReleaseList'
import { ReleaseShow } from './ReleaseShow'

export default {
  icon: GiConverseShoe,
  name: 'Release',
  list: ReleaseList,
  create: ReleaseCreate,
  edit: ReleaseEdit,
  show: ReleaseShow,
}
