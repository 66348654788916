import { FC, useMemo } from 'react'
import {
  Stack,
  Text,
  Image,
  Box,
  Heading,
  FormControl,
  FormErrorMessage,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
  Icon,
} from '@chakra-ui/react'
import { AutocompleteInput, ca, useTranslate } from 'chakra-admin'
import { FormSection } from '../../components/forms/FormSection'
import { QUERY_GET_EXTERNAL_PRODUCTS, QUERY_GET_PRODUCTS } from '../../queries'
import { gql_ExternalProduct, gql_Product, useGetAppQuery } from '../../graphql'
import { NavLink } from 'react-router-dom'
import { Controller } from 'react-hook-form'
import DatePicker from 'react-multi-date-picker'
import { FaArrowRight, FaCalendar } from 'react-icons/fa'
import TimePicker from 'react-multi-date-picker/plugins/time_picker'
import { ReleaseTypeRadio } from './ReleaseTypeRadio'

export const ReleaseForm: FC<any> = (props) => {
  const {
    formState: { errors },
  } = props
  const t = useTranslate()
  const appId = useMemo(() => {
    return localStorage.getItem('appId')
  }, [])

  const { loading, data } = useGetAppQuery({
    variables: {
      id: appId!,
    },
    skip: !appId,
  })

  const hasShopifyIntegration = useMemo(() => {
    return data?.app?.activeIntegrations?.includes('shopify')
  }, [data])

  return (
    <Stack spacing={8} mt={3} mb={20}>
      <Box>
        {/** Just an empty box because for some reasons Chakra UI is giving to the first child a different margin */}
      </Box>
      {/* {!hasShopifyIntegration && (
        <Alert
          display="flex"
          status="warning"
          fontSize="sm"
          my={4}
          alignItems="center"
          borderRadius="lg"
          colorScheme="red"
          variant="subtle"
        >
          <AlertIcon />
          <AlertTitle>Your app does not have any active integration.</AlertTitle>
          <AlertDescription flex="1" pt={-0.5}>
            Please configure one to be able to release products.
          </AlertDescription>
          <Button
            alignSelf="flex-end"
            mt={-1}
            ml={2}
            as={NavLink}
            to="/settings/app-integration"
            size="xs"
            colorScheme="red"
          >
            Configure integration now
          </Button>
        </Alert>
      )} */}
      <FormSection title="Product to release" description="Select the product you want to release">
        <Box width={{ base: '100%', md: '400px' }}>
          <FormControl display="flex" flexDirection="column" alignContent="flex-end" isInvalid={!!errors?.productId}>
            <AutocompleteInput
              {...props}
              paginationMode="cursor"
              inputStyleProps={{
                variant: 'outline',
                borderRadius: 'lg',
                isInvalid: !!errors?.productId,
                size: 'sm',
              }}
              required="Please select a product"
              labelStyleProps={{
                display: 'none',
              }}
              toggleButtonStyleProps={{
                display: 'none',
              }}
              query={QUERY_GET_PRODUCTS}
              source="productId"
              label="Product"
              placeholder="Search product"
              disableCreateItem
              showFormControl={false}
              inputValueToFilters={(name: string) => ({ name })}
              dataItemToAutocompleteItem={(data: gql_Product) => ({
                ...data,
                label: data.name,
                value: data.id,
              })}
              itemRenderer={(item: gql_Product) => {
                return (
                  <Stack direction="row" spacing="4" alignItems="center">
                    <Image
                      src={item.productImages?.[0]?.url}
                      alt={item.productImages?.[0]?.altText}
                      boxSize="60px"
                      bgColor="gray.100"
                      border="none"
                    />
                    <Stack spacing="2">
                      <Heading fontSize="sm" color="blackAlpha.700">
                        {item.name}
                      </Heading>
                      <Text>{item.description}</Text>
                    </Stack>
                  </Stack>
                )
              }}
            />
            <FormErrorMessage>{errors?.externalProductId?.message}</FormErrorMessage>
          </FormControl>
        </Box>
      </FormSection>
      {/* <FormSection title="Product to release" description="Select the product you want to release">
        <Box width={{ base: '100%', md: '400px' }}>
          <FormControl
            display="flex"
            flexDirection="column"
            alignContent="flex-end"
            isInvalid={!!errors?.externalProductId}
          >
            <AutocompleteInput
              {...props}
              paginationMode="cursor"
              inputStyleProps={{
                variant: 'outline',
                borderRadius: 'lg',
                isInvalid: !!errors?.externalProductId,
                size: 'sm',
              }}
              required="Please select a product"
              labelStyleProps={{
                display: 'none',
              }}
              toggleButtonStyleProps={{
                display: 'none',
              }}
              query={QUERY_GET_EXTERNAL_PRODUCTS}
              source="externalProductId"
              label="Product"
              placeholder="Search product"
              disableCreateItem
              showFormControl={false}
              inputValueToFilters={(query: string) => ({ query })}
              dataItemToAutocompleteItem={(data: gql_ExternalProduct) => ({
                ...data,
                label: data.title,
                value: data.id,
              })}
              itemRenderer={(item: gql_ExternalProduct) => {
                return (
                  <Stack direction="row" spacing="4" alignItems="center">
                    <Image
                      src={item.featuredImage?.url}
                      alt={item.featuredImage?.altText}
                      boxSize="60px"
                      bgColor="gray.100"
                      border="none"
                    />
                    <Stack spacing="2">
                      <Heading fontSize="sm" color="blackAlpha.700">
                        {item.title}
                      </Heading>
                      <Text>{item.description}</Text>
                    </Stack>
                  </Stack>
                )
              }}
            />
            <FormErrorMessage>{errors?.externalProductId?.message}</FormErrorMessage>
          </FormControl>
        </Box>
      </FormSection> */}

      <FormSection
        title="Release Dates"
        description="Select the dates on which the customer will able to access the release"
      >
        <FormControl display="flex" flexDirection="column" alignItems="flex-end" isInvalid={!!errors?.dateRange}>
          <Controller
            name="dateRange"
            control={props.control}
            rules={{
              required: 'Please select a date range',
              validate: (value) => (value?.length === 2 ? true : 'Please select also the end date'),
            }}
            render={({ field }) => (
              <DatePicker
                value={field.value}
                onChange={field.onChange}
                range
                rangeHover
                render={<CalendarInput isInvalid={errors?.dateRange} />}
                calendarPosition="bottom-right"
                format="DD/MM/YYYY HH:mm:ss"
                plugins={[<TimePicker key="time-picker-1" position="right" />]}
              />
            )}
          />
          <FormErrorMessage>{errors?.dateRange?.message}</FormErrorMessage>
        </FormControl>
      </FormSection>

      <FormSection title="Type" description="Please select the type of release you want to create">
        <FormControl display="flex" justifyContent="flex-end" isInvalid={!!errors?.dropType}>
          <Controller
            name="dropType"
            control={props.control}
            defaultValue="ONLINE"
            render={({ field }) => (
              <ReleaseTypeRadio
                {...field}
                defaultValue="ONLINE"
                onChange={(value) => {
                  console.log('value', value)
                  field.onChange(value)
                }}
              />
            )}
          />
        </FormControl>
      </FormSection>

      <FormSection title="Published" description="Select if the release should be published or not">
        <FormControl display="flex" justifyContent="flex-end" isInvalid={!!errors?.isPublished}>
          <ca.Switch {...props} source="isPublished" />
        </FormControl>
      </FormSection>
    </Stack>
  )
}

type CalendarInputProps = {
  openCalendar?: () => void
  value?: [string, string]
  handleValueChange?: (value: [string, string]) => void
  isInvalid?: boolean
}

function CalendarInput({ openCalendar, value: propValue, handleValueChange, isInvalid }: CalendarInputProps) {
  const from = useMemo(() => propValue?.[0] || '', [propValue])
  const to = useMemo(() => propValue?.[1] || '', [propValue])

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      role="button"
      onClick={openCalendar}
      border="1px solid"
      py={2}
      px={3}
      maxWidth={{ base: '100%', md: '200px' }}
      borderRadius="lg"
      _hover={{
        borderColor: isInvalid ? 'red.500' : 'gray.300',
      }}
      borderColor={isInvalid ? 'red.500' : 'gray.100'}
      outline="1px solid"
      outlineColor={isInvalid ? 'red.500' : 'rgba(255, 255, 255, 0)'}
      boxSizing="border-box"
    >
      <Stack direction="row" spacing="4" alignItems="center">
        {from && (
          <Text fontSize="sm" as="span">
            {from}
          </Text>
        )}
        {from && <Icon fontSize="sm" as={FaArrowRight} />}
        {from && to ? (
          <Text fontSize="sm" as="span">
            {to}
          </Text>
        ) : from && !to ? (
          <Text fontSize="sm" as="span" color="gray.500">
            Select end date
          </Text>
        ) : (
          ''
        )}

        {!from && !to && (
          <Text fontSize="sm" as="span" color="gray.500">
            Select date range
          </Text>
        )}

        <Icon fontSize="sm" as={FaCalendar} />
      </Stack>
    </Box>
  )
}
