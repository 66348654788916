import { Button, ButtonProps, useToast } from '@chakra-ui/react'
import {
  gql_ReleaseFullfillmentQueueReportSubscription,
  gql_ReportStatus,
  useGetReleaseFullfillmentQueueStatusQuery,
  useReleaseRequestFulfillmentMutation,
} from '../../../graphql'
import { useCallback, useEffect, useMemo } from 'react'
import { useVersion } from 'chakra-admin'
import { SUBSCRIPTION_RELEASE_FULLFILLMENT_QUEUE_REPORT } from '../../../queries'

export type RequestFullfillmentButtonProps = { record?: any } & ButtonProps

export function RequestFullfillmentButton({ record, ...props }: RequestFullfillmentButtonProps) {
  const [requestFullfillment, { loading, data, error }] = useReleaseRequestFulfillmentMutation()
  const toast = useToast()
  const nextVersion = useVersion()

  const {
    loading: dfsLoading,
    data: dfsData,
    error: dfsError,
    networkStatus: dfsNetworkStatus,
    subscribeToMore: dfsSubscribeToMore,
  } = useGetReleaseFullfillmentQueueStatusQuery({
    variables: {
      id: props?.id || '',
    },
    skip: !props?.id,
  })

  const handleClick = useCallback(async () => {
    try {
      await requestFullfillment({
        variables: {
          id: props?.id!,
        },
      })
      toast({
        title: 'Fullfillment requested',
        description: 'The fullfillment has been requested',
        status: 'success',
        duration: 1000,
        isClosable: true,
      })
    } catch (err) {
      console.error(err)
      toast({
        title: 'Error requesting fullfillment',
        description: (err as any).message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }, [props?.id, requestFullfillment, toast])

  const isLoading = useMemo(() => {
    return (
      dfsLoading ||
      loading ||
      dfsData?.releaseFullfillmentQueueStatus?.status === gql_ReportStatus.Pending ||
      dfsData?.releaseFullfillmentQueueStatus?.status === gql_ReportStatus.Processing
    )
  }, [dfsData?.releaseFullfillmentQueueStatus?.status, dfsLoading, loading])

  useEffect(() => {
    if (!props?.id) {
      return
    }

    const drawQueueUnsub = dfsSubscribeToMore<gql_ReleaseFullfillmentQueueReportSubscription>({
      document: SUBSCRIPTION_RELEASE_FULLFILLMENT_QUEUE_REPORT,
      variables: { id: props?.id || '' },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev
        }

        const newEntry = subscriptionData.data.releaseFullfillmentQueueReport
        return Object.assign({}, prev, {
          releaseFullfillmentQueueStatus: newEntry,
        })
      },
    })

    return () => {
      drawQueueUnsub()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.id])

  if (!record?.id || record?.status === 'OPEN') {
    return null
  }

  return (
    <Button {...props} colorScheme="blue" isDisabled={isLoading} isLoading={isLoading} onClick={handleClick}>
      Request fullfillment
    </Button>
  )
}
