/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { DocumentNode, gql, OperationVariables, QueryResult, TypedDocumentNode } from '@apollo/client'
import { generateFields, DefaultShowStrategy } from 'chakra-admin'
import { query } from 'gql-query-builder'
import omit from 'lodash.omit'
import { gql_Admin } from '../graphql'
import { formatDate, resolvePicture } from './utils'

export class EditStrategy extends DefaultShowStrategy {
  getQuery(
    resource: string,
    operation: string,
    variables?: OperationVariables,
    fields?: string[],
  ): DocumentNode | TypedDocumentNode<any, OperationVariables> {
    const result = query({
      operation,
      variables: {
        id: {
          value: variables?.id,
          type: 'ID',
          required: true,
        },
      },
      fields: generateFields(fields),
    })

    return gql(result.query)
  }

  getItem = (({ data }: QueryResult<any, OperationVariables>): any => {
    const toReturnData =
      data && Object.keys(data).length > 0 && (data as any)[Object.keys(data)[0]]
        ? (data as any)[Object.keys(data)[0]]
        : {}

    switch (toReturnData.__typename) {
      case 'Admin': {
        const { ...rest } = toReturnData as gql_Admin

        return {
          ...rest,
        }
      }

      case 'Release': {
        const { __typename, startDate, endDate, ...rest } = toReturnData as any

        return {
          ...rest,
          startDate: startDate ? new Date(startDate) : null,
          endDate: endDate ? new Date(endDate) : null,
          dateRange: [new Date(startDate).getTime(), new Date(endDate).getTime()],
        }
      }

      default:
        return toReturnData
    }
  }) as any

  getItemVariables = (id: string): OperationVariables => {
    return { id }
  }

  getMutationVariables = (id: string, values: Record<string, any>): OperationVariables => {
    switch (values.__typename) {
      case 'Admin': {
        const { __typename, createdAt, updatedAt, ...rest } = values as gql_Admin

        return {
          id,
          data: {
            ...omit(rest, 'id'),
            // picture: resolvePicture(picture),
          },
        }
      }

      case 'Release': {
        const { __typename, dateRange, createdAt, updatedAt, product, ...rest } = values as any

        return {
          id,
          data: {
            ...omit(rest, 'id'),
            startDate: new Date(dateRange[0]).toISOString(),
            endDate: new Date(dateRange[1]).toISOString(),
          },
        }
      }

      default:
        const { id: iId, __typename, createdAt, updatedAt, ...data } = values as any

        console.log('data', data)

        return {
          id,
          data,
        }
    }
  }
}
