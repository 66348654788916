import { gql_Release } from '../../../graphql'
import { Box } from '@chakra-ui/react'
import { ReleaseRunningAlert } from './ReleaseRunningAlert'
import Loading from '../../../components/base/Loading'
import { EntriesTable } from './EntriesTable'
import { ReleaseStockTable } from './ReleaseStockTable'

export type SelectWinnersProps = {
  id: string
  record: Partial<gql_Release>
}

export function SelectWinners({ id, record, ...props }: SelectWinnersProps) {
  if (!record?.id) {
    return <Loading />
  }

  return (
    <Box>
      <ReleaseRunningAlert record={record} mx={4} mb={8} />

      {record.status === 'OPEN' && <ReleaseStockTable id={id} record={record} />}
      {record.status === 'SELECTING' && <EntriesTable id={id} record={record} />}
    </Box>
  )
}
