import { Card, CardBody, CardProps, Heading, Progress, Text, Box, IconButton } from '@chakra-ui/react'
import {
  gql_ReleaseDrawQueueReportSubscription,
  gql_ReleaseFullfillmentQueueReportSubscription,
  gql_ReportStatus,
  useGetReleaseDrawQueueStatusQuery,
  useGetReleaseFullfillmentQueueStatusQuery,
} from '../../../graphql'
import { useCallback, useEffect, useMemo } from 'react'
import {
  SUBSCRIPTION_RELEASE_DRAW_QUEUE_REPORT,
  SUBSCRIPTION_RELEASE_FULLFILLMENT_QUEUE_REPORT,
} from '../../../queries'
import { CloseIcon } from '@chakra-ui/icons'
import React from 'react'

export type ReleaseDrawQueueStatusCardProps = {
  id: string
} & Omit<CardProps, 'id'>

export function ReleaseDrawQueueStatusCard(props: ReleaseDrawQueueStatusCardProps) {
  const [hide, setHide] = React.useState(false)
  const { data, subscribeToMore } = useGetReleaseDrawQueueStatusQuery({
    variables: {
      id: props?.id || '',
    },
    skip: !props?.id,
  })

  const handleCloseClick = useCallback(() => {
    setHide(true)
  }, [])

  useEffect(() => {
    if (!props?.id) {
      return
    }

    const drawQueueUnsub = subscribeToMore<gql_ReleaseDrawQueueReportSubscription>({
      document: SUBSCRIPTION_RELEASE_DRAW_QUEUE_REPORT,
      variables: { id: props?.id || '' },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev
        }

        const newEntry = subscriptionData.data.releaseDrawQueueReport
        return Object.assign({}, prev, {
          releaseDrawQueueStatus: newEntry,
        })
      },
    })

    return () => {
      drawQueueUnsub()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.id])

  useEffect(() => {
    if (
      hide &&
      data?.releaseDrawQueueStatus?.status !== gql_ReportStatus.Complete &&
      data?.releaseDrawQueueStatus?.status !== gql_ReportStatus.Error
    ) {
      setHide(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.releaseDrawQueueStatus?.status])

  if (!data || data?.releaseDrawQueueStatus?.status === gql_ReportStatus.None || hide) {
    return null
  }

  return (
    <ReleaseQueueCard
      status={data?.releaseDrawQueueStatus?.status}
      message={data?.releaseDrawQueueStatus?.message}
      progress={data?.releaseDrawQueueStatus.progress as [number, number]}
      onClose={handleCloseClick}
      {...props}
    />
  )
}

export type ReleaseFullfillmentQueueStatusCardProps = {
  totalSelectedForConfirmation: number
} & CardProps

export function ReleaseFullfillmentQueueStatusCard({
  totalSelectedForConfirmation,
  ...props
}: ReleaseFullfillmentQueueStatusCardProps) {
  const [hide, setHide] = React.useState(false)

  const { data, subscribeToMore } = useGetReleaseFullfillmentQueueStatusQuery({
    variables: {
      id: props?.id || '',
    },
    skip: !props?.id,
  })

  const handleCloseClick = useCallback(() => {
    setHide(true)
  }, [])

  useEffect(() => {
    if (!props?.id) {
      return
    }

    const drawQueueUnsub = subscribeToMore<gql_ReleaseFullfillmentQueueReportSubscription>({
      document: SUBSCRIPTION_RELEASE_FULLFILLMENT_QUEUE_REPORT,
      variables: { id: props?.id || '' },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev
        }

        const newEntry = subscriptionData.data.releaseFullfillmentQueueReport
        const newObj = {
          ...newEntry,
        }

        if (newObj.progress) {
          newObj.progress = [newObj.progress?.[0] || 0, newObj.progress?.[1] || totalSelectedForConfirmation]
        }
        return Object.assign({}, prev, {
          releaseFullfillmentQueueStatus: {
            ...newObj,
          },
        })
      },
    })

    return () => {
      drawQueueUnsub()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.id, totalSelectedForConfirmation])

  if (!data || data?.releaseFullfillmentQueueStatus?.status === gql_ReportStatus.None || hide) {
    return null
  }

  return (
    <ReleaseQueueCard
      status={data?.releaseFullfillmentQueueStatus?.status}
      message={data?.releaseFullfillmentQueueStatus?.message}
      progress={data?.releaseFullfillmentQueueStatus.progress as [number, number]}
      onClose={handleCloseClick}
      {...props}
    />
  )
}

function ReleaseQueueProgress({ progress }: { progress: [number, number] }) {
  console.log('progress', progress)
  let percentage = useMemo(() => {
    if (progress[1] === 0) {
      return 0
    }

    return Math.round((progress[0] / progress[1]) * 100)
  }, [progress])

  return (
    <Box w="100%" mt={4}>
      <Text textAlign="center">{percentage}%</Text>
      <Progress value={percentage} isIndeterminate={progress[1] === 0} />
    </Box>
  )
}

function ReleaseQueueCard({
  status,
  message,
  progress,
  onClose,
  ...props
}: {
  status: gql_ReportStatus
  message: string
  progress?: [number, number]
  onClose?: () => void
} & CardProps) {
  return (
    <Card pos="relative" {...props}>
      <CardBody>
        {(status === gql_ReportStatus.Complete || status === gql_ReportStatus.Error) && (
          <IconButton
            variant="ghost"
            borderRadius="full"
            size="xs"
            aria-label="Close"
            icon={<CloseIcon />}
            position="absolute"
            top={2}
            right={2}
            onClick={onClose}
          />
        )}
        <Heading size="sm">Queue Status {status}</Heading>
        <Text>{message}</Text>
        {progress && <ReleaseQueueProgress progress={progress} />}
      </CardBody>
    </Card>
  )
}
