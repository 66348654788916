import { Box, Heading, Image, Link, Skeleton } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'
import hypertingLogo from '../../../../assets/icons/hyperting.png'
import { useGetAppQuery } from '../../../../graphql'

export type NavLogoProps = {
  appId: string
}

export function NavLogo({ appId }: NavLogoProps) {
  const { loading, data } = useGetAppQuery({
    variables: {
      id: appId,
    },
  })

  return (
    <Link
      as={NavLink}
      w={{ base: '100%', lg: '200px' }}
      display="flex"
      to="/"
      alignItems="center"
      _hover={{
        textDecoration: 'none',
      }}
    >
      <Skeleton isLoaded={!loading} borderRadius="md">
        <Image boxSize="30px" src={hypertingLogo} />
      </Skeleton>
      <Skeleton isLoaded={!loading} w="200px" ml={2} height="24px" borderRadius="md">
        <Heading size="md" fontWeight="bold" ml={2} textDecoration="none">
          {data?.app?.name}
        </Heading>
      </Skeleton>
    </Link>
  )
}
