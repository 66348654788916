import { Button, chakra, FormControl, FormErrorMessage, HStack, useToast } from '@chakra-ui/react'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useGetStaffMemberMeQuery, useUpdateStaffMemberMeNameMutation } from '../../../graphql'
import { Section, SectionTitle, SectionDescription, SectionInput, SectionFooter } from '../setting-section'
import { ConfirmToast } from '../ConfirmToast'
import { CancelSettingButton, SaveSettingButton } from '../settings-buttons'

type FormData = {
  firstName: string
  lastName: string
}

function EmailFormSkeleton() {
  return (
    <Section w="100%" mt={0}>
      <SectionTitle isLoaded={false}>Your Name</SectionTitle>
      <SectionDescription isLoaded={false} noOfLines={1}>
        Name is used to identify you as a merchant operator.
      </SectionDescription>

      <HStack spacing={4}>
        <FormControl width="auto">
          <SectionInput isDisabled placeholder="John" />
        </FormControl>
        <FormControl width="auto">
          <SectionInput isDisabled placeholder="Doe" />
        </FormControl>
      </HStack>
      <SectionFooter>
        <Button colorScheme="primary" size="sm" isDisabled>
          Save
        </Button>
      </SectionFooter>
    </Section>
  )
}

type NameSettingFormProps = {
  defaultValues: FormData
}

function NameSettingForm({ defaultValues }: NameSettingFormProps) {
  const [update, { loading: saving }] = useUpdateStaffMemberMeNameMutation()
  const toast = useToast()

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, errors },
  } = useForm<FormData>({
    defaultValues,
  })

  const handleReset = useCallback(() => {
    reset()
  }, [reset])

  const onSubmit = useCallback(
    async (data: FormData) => {
      try {
        const result = await update({
          variables: {
            firstName: data.firstName,
            lastName: data.lastName,
          },
        })
        toast({
          description: 'Name updated.',
          status: 'info',
          render: ConfirmToast,
          duration: 5000,
          isClosable: true,
        })
        reset({
          firstName: result.data?.updateStaffMemberMeName.firstName,
          lastName: result.data?.updateStaffMemberMeName.lastName,
        })
      } catch (e) {
        toast({
          title: 'Error updating name, please try again later.',
          description: `${(e as any).message}.`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    },
    [reset, toast, update]
  )

  return (
    <Section w="100%">
      <chakra.form onSubmit={handleSubmit(onSubmit)}>
        <SectionTitle>Your Name</SectionTitle>
        <SectionDescription>Name is used to identify you as a merchant operator.</SectionDescription>
        <HStack spacing={4} alignItems="flex-start">
          <FormControl width="auto" isInvalid={!!errors?.firstName}>
            <SectionInput
              isDisabled={saving}
              {...register('firstName', {
                required: 'First name is required',
              })}
              placeholder="John"
            />
            {!!errors.firstName && <FormErrorMessage pb={5}>{errors.firstName.message}</FormErrorMessage>}
          </FormControl>

          <FormControl width="auto" isInvalid={!!errors?.lastName}>
            <SectionInput
              isDisabled={saving}
              {...register('lastName', {
                required: 'Last name is required',
              })}
              placeholder="Doe"
            />
            {!!errors.lastName && <FormErrorMessage pb={5}>{errors.lastName.message}</FormErrorMessage>}
          </FormControl>
        </HStack>

        <SectionFooter>
          {isDirty && <CancelSettingButton onClick={handleReset}>Cancel</CancelSettingButton>}
          <SaveSettingButton type="submit" isDisabled={!isDirty || saving} isLoading={saving}>
            Save
          </SaveSettingButton>
        </SectionFooter>
      </chakra.form>
    </Section>
  )
}

export type NameSettingProps = {}

export function NameSetting(props: NameSettingProps) {
  const { loading, data } = useGetStaffMemberMeQuery()
  const defaultValues = useMemo<FormData>(() => {
    return {
      firstName: data?.staffMemberMe?.firstName ?? '',
      lastName: data?.staffMemberMe?.lastName ?? '',
    }
  }, [data])

  if (loading) {
    return <EmailFormSkeleton />
  }

  return <NameSettingForm defaultValues={defaultValues} />
}
