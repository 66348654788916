import React, { FC, useMemo } from 'react'
import { EditButton, ListToolbar, Show } from 'chakra-admin'
import { gql_Query, gql_Release } from '../../graphql'
import { MUTATION_UPDATE_RELEASE, QUERY_GET_RELEASE } from '../../queries'
import { Route, Routes, useLocation } from 'react-router-dom'
import { SelectWinners } from './ReleaseSelectWinners'
import { ReleaseSelectWinnersButton } from './ReleaseSelectWinners/ReleaseSelectWinnersButton'
import { RequestDrawButton } from './ReleaseSelectWinners/RequestDrawButton'
import { RequestFullfillmentButton } from './ReleaseSelectWinners/RequestFullfillmentButton'

export const ReleaseShow: FC<{ id: string }> = (props) => {
  const location = useLocation()

  return (
    <Show<gql_Query, gql_Release>
      query={QUERY_GET_RELEASE}
      queryOptions={{
        fetchPolicy: 'network-only',
      }}
      mutation={MUTATION_UPDATE_RELEASE}
      toolbarComponent={
        location.pathname.includes('select-winners') ? (
          <ListToolbar>
            <RequestDrawButton />
            <RequestFullfillmentButton />
          </ListToolbar>
        ) : (
          <ListToolbar>
            <ReleaseSelectWinnersButton />

            <EditButton />
          </ListToolbar>
        )
      }
      {...props}
    >
      <ReleaseRoutes />
    </Show>
  )
}

function ReleaseRoutes({ ...props }: any) {
  const location = useLocation()
  const background = useMemo(() => (location?.state as { background?: Location })?.background, [location?.state])

  return (
    <Routes location={background || location}>
      <Route index element={<JsonPrint {...props} />} />
      <Route path="select-winners" element={<SelectWinners {...props} />} />
    </Routes>
  )
}

function JsonPrint(props: any) {
  return (
    <pre>
      <code>{JSON.stringify(props.record, null, 2)}</code>
    </pre>
  )
}
