import {
  Heading,
  Icon,
  Skeleton,
  Text,
  Box,
  Button,
  useDisclosure,
  useBoolean,
  Stack,
  FormControl,
  Input,
  FormLabel,
  Divider,
  FormErrorMessage,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { useCallback, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { FaExclamationTriangle, FaShopify } from 'react-icons/fa'
import { TbPlug, TbPlugOff } from 'react-icons/tb'
import { useUpdateAppMutation } from '../../../../graphql'
import { AuthorizeAppIcon, ConnectModal } from './connect-modal'
import { IntegrationBox } from './integration-box'

type FormData = {
  shopName: string
  adminApiAccessToken: string
  apiKey: string
  apiSecretKey: string
}

export type ShopifyIntegrationProps = {
  hasShopifyIntegration?: boolean
  loading?: boolean
  appId: string
}

function ShopifyIntegrationSkeleton() {
  return (
    <IntegrationBox>
      <Skeleton boxSize="50px" borderRadius="full" />
    </IntegrationBox>
  )
}

export function ShopifyIntegration({ loading, hasShopifyIntegration, appId }: ShopifyIntegrationProps) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<FormData>()
  const [update, { loading: updating, data, error, reset: resetMutation }] = useUpdateAppMutation()
  const formRef = useRef<HTMLFormElement>(null)
  const [showSetIntegrationForm, { off: offSetIntegrationForm, on: onSetIntegrationForm }] = useBoolean(false)
  const {
    onClose: onCloseSetIntegration,
    isOpen: isSetIntegrationOpen,
    onOpen: onOpenSetIntegration,
  } = useDisclosure({
    onClose: () => {
      offSetIntegrationForm()
      reset()
      resetMutation()
    },
  })
  const {
    onClose: onCloseRemoveIntegration,
    isOpen: isRemoveIntegrationOpen,
    onOpen: onOpenRemoveIntegration,
  } = useDisclosure({
    onClose: () => {
      resetMutation()
    },
  })

  const onSubmit = useCallback(
    async (values: FormData) => {
      try {
        const { data } = await update({
          variables: {
            id: appId,
            data: {
              integration: {
                shopify: {
                  shop_name: values.shopName,
                  admin_api_access_token: values.adminApiAccessToken,
                  api_key: values.apiKey,
                  api_secret_key: values.apiSecretKey,
                },
              },
            },
          },
        })

        onCloseSetIntegration()
      } catch (error) {
        console.log('error', error)
      }
    },
    [appId, update],
  )

  const handleDisconnect = useCallback(async () => {
    try {
      const { data } = await update({
        variables: {
          id: appId,
          data: {
            integration: {},
          },
        },
      })

      onCloseRemoveIntegration()
    } catch (error) {}
  }, [appId, onCloseRemoveIntegration, update])

  if (loading) {
    return <ShopifyIntegrationSkeleton />
  }

  return (
    <>
      <IntegrationBox
      // error outline
      // outline={!stripeAccount.stripeAccount.details_submitted ? '2px solid' : 'none'}
      >
        <Icon color="shopify" as={FaShopify} boxSize="70px" />

        <Box flex="1" pl={6}>
          <Heading color="blackAlpha.700" as="h3" size="sm">
            Shopify
          </Heading>
          <Text color="blackAlpha.600" fontSize="sm">
            Integrate customers, products and orders from and to Shopify.
          </Text>
        </Box>
        {hasShopifyIntegration ? (
          <Button
            borderRadius="md"
            // leftIcon={<Icon as={TbPlugOff} />}
            colorScheme="primary"
            size="sm"
            variant="solid"
            fontWeight="normal"
            boxShadow="md"
            onClick={onOpenRemoveIntegration}
          >
            Connected
          </Button>
        ) : (
          <Button
            onClick={onOpenSetIntegration}
            borderRadius="md"
            leftIcon={<Icon as={TbPlug} />}
            size="sm"
            variant="outline"
            fontWeight="normal"
            // isLoading={isOpen}
          >
            Connect
          </Button>
        )}
      </IntegrationBox>

      <ConnectModal
        isOpen={isSetIntegrationOpen}
        onClose={onCloseSetIntegration}
        onConfirmClick={!showSetIntegrationForm ? onSetIntegrationForm : () => formRef?.current?.submit()}
        confirmText={showSetIntegrationForm ? 'Connect' : 'Start connecting'}
        confirmButtonProps={{
          form: 'shopify-integration',
          type: showSetIntegrationForm ? 'submit' : undefined,
          isLoading: updating,
          isDisabled: !showSetIntegrationForm ? false : !isDirty || updating,
        }}
        rightApp={
          <AuthorizeAppIcon>
            <Icon as={FaShopify} color="shopify" boxSize="40px" />
          </AuthorizeAppIcon>
        }
        title="Connect with Shopify"
        description="Please fill necessary data to connect with Shopify"
      >
        <>
          {showSetIntegrationForm && (
            <motion.div
              initial={{
                height: 0,
                opacity: 0,
              }}
              animate={{
                height: 'auto',
                opacity: 1,
              }}
              transition={{
                type: 'spring',
                stiffness: 550,
                damping: 50,
              }}
            >
              <form id="shopify-integration" onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={6} pt={2} pb={6}>
                  <FormControl isInvalid={!!errors?.shopName}>
                    <FormLabel fontSize="sm" htmlFor="shopify-integration-shop-name">
                      Shop Name
                    </FormLabel>
                    <Input
                      size="sm"
                      id="shopify-integration-shop-name"
                      {...register('shopName', {
                        required: 'Please enter your shop name',
                      })}
                      type="text"
                      placeholder="Shop Name"
                    />
                    {errors.shopName && <FormErrorMessage>{errors.shopName.message}</FormErrorMessage>}
                  </FormControl>

                  <Divider />

                  <FormControl isInvalid={!!errors?.adminApiAccessToken}>
                    <FormLabel fontSize="sm" htmlFor="shopify-integration-admin-api-access-token">
                      Admin API Access Token
                    </FormLabel>
                    <Input
                      size="sm"
                      id="shopify-integration-admin-api-access-token"
                      {...register('adminApiAccessToken', {
                        required: 'Please enter your Shopify App Admin API Access Token',
                      })}
                      type="text"
                      placeholder="Admin API Access Token"
                    />
                    {errors.adminApiAccessToken && (
                      <FormErrorMessage>{errors.adminApiAccessToken.message}</FormErrorMessage>
                    )}
                  </FormControl>

                  <FormControl isInvalid={!!errors?.apiKey}>
                    <FormLabel fontSize="sm" htmlFor="shopify-integration-api-key">
                      API Key
                    </FormLabel>
                    <Input
                      size="sm"
                      id="shopify-integration-api-key"
                      {...register('apiKey', {
                        required: 'Please enter your Shopify App API Key',
                      })}
                      type="text"
                      placeholder="Admin API Key"
                    />
                    {errors.apiKey && <FormErrorMessage>{errors.apiKey.message}</FormErrorMessage>}
                  </FormControl>

                  <FormControl isInvalid={!!errors?.apiSecretKey}>
                    <FormLabel fontSize="sm" htmlFor="shopify-integration-api-secret-key">
                      API Secret Key
                    </FormLabel>
                    <Input
                      size="sm"
                      id="shopify-integration-api-secret-key"
                      {...register('apiSecretKey', {
                        required: 'Please enter your Shopify App API Secret Key',
                      })}
                      type="text"
                      placeholder="API Secret Key"
                    />
                    {errors.apiSecretKey && <FormErrorMessage>{errors.apiSecretKey.message}</FormErrorMessage>}
                  </FormControl>
                </Stack>
              </form>

              {error && (
                <Alert
                  status="error"
                  variant="solid"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="lg"
                >
                  <AlertDescription>{error.message}</AlertDescription>
                </Alert>
              )}
            </motion.div>
          )}
        </>
      </ConnectModal>

      <ConnectModal
        isDisconnect
        isOpen={isRemoveIntegrationOpen}
        onClose={onCloseRemoveIntegration}
        onConfirmClick={handleDisconnect}
        confirmText="Disconnect Shopify"
        confirmButtonProps={{
          form: 'shopify-integration',
          type: showSetIntegrationForm ? 'submit' : undefined,
          isLoading: updating,
          colorScheme: 'red',
        }}
        rightApp={
          <AuthorizeAppIcon>
            <Icon as={FaShopify} color="shopify" boxSize="40px" />
          </AuthorizeAppIcon>
        }
        title="Remove Shopify Integration"
        description="Are you sure you want to remove Shopify integration?"
      >
        <Text textAlign="center" fontSize="sm" color="red">
          <Icon as={FaExclamationTriangle} color="red" mr={1} mb="-2px" />
          Removing Shopify integration will remove all the data from Shopify and you will not be able to use it anymore.
        </Text>

        {error && (
          <Alert
            status="error"
            variant="solid"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            borderRadius="lg"
          >
            <AlertDescription>{error.message}</AlertDescription>
          </Alert>
        )}
      </ConnectModal>
    </>
  )
}
