import { extendTheme } from '@chakra-ui/react'
import { theme as caTheme } from 'ca-theme'

export const theme = extendTheme({
  // ...caTheme,
  components: {
    ...caTheme.components,
    Switch: {
      defaultProps: {
        colorScheme: 'primary',
        size: 'md',
      },
    },
    CalendarControl: {
      parts: ['controls', 'button'],

      baseStyle: {
        button: {
          h: 6,
          w: 6,
          rounded: 'none',
          fontSize: 'sm',
          color: '#4A4F5E',
          bgColor: '#F2F4F8',
          _hover: {
            bgColor: 'gray.200',
          },
          _focus: {
            outline: 'none',
          },
        },
        controls: {
          position: 'absolute',
          borderRadius: '6px',
          overflow: 'hidden',
          m: 4,
          right: 0,
        },
      },
    },
    CalendarMonth: {
      parts: ['month', 'name', 'week', 'weekday', 'days'],
      baseStyle: {
        name: {
          h: 8,
          fontSize: 'md',
          lineHeight: 6,
          textAlign: 'left',
          textTransform: 'capitalize',
        },
        week: {
          gridTemplateColumns: 'repeat(7, 1fr)',
        },
        weekday: {
          textAlign: 'center',
          textTransform: 'capitalize',
          color: '#B7BAC8',
          fontSize: '11px',
          fontWeight: '400',
          lineHeight: '16px',
          margin: '8px auto',
        },

        days: {
          gridTemplateColumns: 'repeat(7, 1fr)',
        },
      },

      defaultProps: {
        name: {
          as: 'h2',
        },
      },
    },
    CalendarDay: {
      baseStyle: {
        rounded: 'none',
        bgColor: 'transparent',
        fontSize: '14px',
        fontWeight: '500',
        w: '40px !important',
        height: '40px !important',

        _hover: {
          bgColor: 'gray.100',
        },

        _disabled: {
          color: 'gray.200',
          _hover: {
            cursor: 'initial',
            bgColor: 'transparent',
          },
        },
      },

      sizes: {
        sm: {
          h: 8,
        },
      },

      variants: {
        selected: {
          position: 'relative',
          color: 'white',
          bgColor: '#DEDFFB',
          ':first-of-type > p': {
            backgroundColor: '#5D5FEF',
            rounded: 'full',
            height: '100%',
            width: '100%',
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            _hover: {
              bgColor: '#8D8FF3',
            },
          },
        },
        range: {
          bgColor: '#DEDFFB',
          color: '#5D5FEF',
          _hover: {
            bgColor: '#EEEFFD',
          },
          _disabled: {
            bgColor: 'gray.200',
            _hover: {
              bgColor: 'gray.200',
            },
          },
        },
        outside: {
          color: 'gray.300',
        },
      },
      defaultProps: {
        size: 'sm',
      },
    },
  },
  textStyles: {
    h1: {
      fontSize: {
        base: '28px',
        md: '28px',
      },
      fontWeight: '700',
      lineHeight: '35.28px',
      letterSpacing: '0.4px',
      color: 'gray.900',
    },
    h2: {
      fontSize: {
        base: '24px',
        md: '24px',
      },
      fontWeight: '600',
      lineHeight: '30.24px',
      letterSpacing: '0.4px',
      color: 'gray.900',
    },
    subtitle: {
      fontSize: {
        base: '19px',
        md: '19px',
      },
      fontWeight: '400',
      lineHeight: '23.94px',
      letterSpacing: '0.4px',
      color: 'gray.500',
    },
    description: {
      fontSize: {
        base: '14px',
        md: '14px',
      },
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '0.3px',
      color: 'gray.500',
    },
    inputDesc: {
      fontSize: {
        base: '12px',
        md: '12px',
      },
      fontWeight: '700',
      lineHeight: '15.12px',
      letterSpacing: '0.3px',
      color: 'gray.500',
      textTransform: 'uppercase',
    },
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '0.9375rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '3.75rem',
    '7xl': '4.5rem',
    '8xl': '6rem',
    '9xl': '8rem',
  },
  fonts: {
    body: '"Inter", sans-serif',
    heading: '"Inter", sans-serif',
    // body: '"Quicksand", sans-serif',
    // heading: '"Quicksand", sans-serif',
  },
  styles: {},
  colors: {
    primary: {
      50: '#ffffff',
      100: '#E8E8FD',
      200: '#babbf8',
      300: '#a3a4f6',
      400: '#8b8df4',
      500: 'rgba(93, 95, 239, 1)',
      600: '#2f31ea',
      700: '#171ae8',
      800: '#1517d1',
      900: '#1012a3',
    },
    background: {
      50: 'rgba(247, 249, 252, 1)',
      100: 'rgba(255, 255, 255, 0.06)',
      200: 'rgba(255, 255, 255, 0.08)',
      300: 'rgba(255, 255, 255, 0.16)',
      400: 'rgba(255, 255, 255, 0.24)',
      500: 'rgba(255, 255, 255, 0.36)',
      600: 'rgba(255, 255, 255, 0.48)',
      700: 'rgba(255, 255, 255, 0.64)',
      800: 'rgba(255, 255, 255, 0.80)',
      900: 'rgba(255, 255, 255, 0.92)',
    },
    primarytext: {
      50: 'rgba(255, 255, 255, 0.04)',
      100: 'rgba(255, 255, 255, 0.06)',
      200: 'rgba(255, 255, 255, 0.08)',
      300: 'rgba(255, 255, 255, 0.16)',
      400: 'rgba(255, 255, 255, 0.24)',
      500: 'rgba(255, 255, 255, 0.36)',
      600: 'rgba(255, 255, 255, 0.48)',
      700: 'rgba(255, 255, 255, 0.64)',
      800: 'rgba(255, 255, 255, 0.80)',
      900: 'rgba(255, 255, 255, 0.92)',
    },
    blackAlpha: {
      100: 'rgba(0, 0, 0, 0.36)',
      900: 'rgba(0, 0, 0, 0.8)',
    },
    gray: {
      50: '#FBFBFB',
      100: '#EEEEEE',
      200: '#E9E9E8',
      300: '#A6B0B9',
      400: '#bababa',
      500: '#A1A1A1',
      600: '#5e5555',
      700: '#2B2B40',
      800: '#333333',
      900: '#251F1E',
    },
    red: {
      50: '#ffffff',
      100: '#fee7e7',
      200: '#fdcecf',
      300: '#fbb6b8',
      400: '#fa9ea0',
      500: '#F86D70',
      600: '#f63c40',
      700: '#F12B2C',
      800: '#f30c11',
      900: '#c3090d',
    },
    orange: {
      50: '#fbe8d3',
      100: '#f6d1a4',
      200: '#f4c58d',
      300: '#f2b976',
      400: '#efad5f',
      500: '#EB9530',
      600: '#d47c14',
      700: '#bc6e12',
      800: '#a56010',
      900: '#77450b',
    },
    blue: {
      50: '#E6E9F0',
      100: '#bee3f8',
      200: '#90cdf4',
      300: '#63b3ed',
      400: '#4299e1',
      500: '#3182ce',
      600: '#2b6cb0',
      700: '#2c5282',
      800: '#2a4365',
      900: '#1A365D',
    },
    shopify: '#96bf48',
  },
  shadows: {
    outline: 'none',
    main: '0px 6px 47px rgba(0, 0, 0, 0.08), inset 0px 1px 2px rgba(0, 0, 0, 0.01), inset 0px 0px 1px rgba(0, 0, 0, 0.1), inset 0px -1px 1px rgba(0, 0, 0, 0.05);',
  },
})
