import { FC, useMemo } from 'react'
import { Stack, Box, FormControl, FormErrorMessage, Textarea } from '@chakra-ui/react'
import { ca, useTranslate } from 'chakra-admin'
import { FormSection } from '../../components/forms/FormSection'
import { useGetAppQuery } from '../../graphql'
import { Controller } from 'react-hook-form'
import { ProductAttributesInput } from '../../components/inputs/product-attributes'
import { ProductCombinationsTable } from '../../components/inputs/product-combinations'

export const ProductForm: FC<any> = (props) => {
  const {
    formState: { errors },
    register,
  } = props
  const t = useTranslate()
  const appId = useMemo(() => {
    return localStorage.getItem('appId')
  }, [])

  const { loading, data } = useGetAppQuery({
    variables: {
      id: appId!,
    },
    skip: !appId,
  })

  return (
    <Stack spacing={8} mt={3} mb={20}>
      <Box>
        {/** Just an empty box because for some reasons Chakra UI is giving to the first child a different margin */}
      </Box>

      <FormSection title="Title" description="Insert the title of the product">
        <FormControl display="flex" flexDirection="column" isInvalid={!!errors?.name}>
          <ca.Input
            variant="outline"
            borderRadius="lg"
            minW={{ base: '100%', lg: '50%' }}
            isInvalid={!!errors?.name}
            size="sm"
            {...props}
            source="name"
            label="Title"
            placeholder="Insert a product name"
          />
          <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
        </FormControl>
      </FormSection>
      <FormSection title="Description" description="Insert the description of the product">
        <FormControl display="flex" flexDirection="column" isInvalid={!!errors?.description}>
          <Textarea
            variant="outline"
            {...register('description')}
            source="description"
            label="Description"
            placeholder="Insert a description"
          />
          <FormErrorMessage>{errors?.description?.message}</FormErrorMessage>
        </FormControl>
      </FormSection>

      <FormSection title="Brand" description="Insert the brand of the product">
        <FormControl display="flex" flexDirection="column" isInvalid={!!errors?.brand}>
          <ca.Input
            variant="outline"
            borderRadius="lg"
            minW={{ base: '100%', lg: '50%' }}
            isInvalid={!!errors?.brand}
            size="sm"
            {...props}
            source="brand"
            label="Brand"
            placeholder="Insert product brand here..."
          />
          <FormErrorMessage>{errors?.brand?.message}</FormErrorMessage>
        </FormControl>
      </FormSection>

      <FormSection title="Tax rate" description="Insert the tax rate for this product (in %)">
        <FormControl display="flex" flexDirection="column" isInvalid={!!errors?.taxRate}>
          <ca.Input
            {...props}
            variant="outline"
            borderRadius="lg"
            minW={{ base: '100%', lg: '50%' }}
            isInvalid={!!errors?.name}
            size="sm"
            source="taxRate"
            label="Tax rate (%)"
            required
            placeholder="22"
            type="number"
            min={0}
            step={1}
          />
          <FormErrorMessage>{errors?.taxRate?.message}</FormErrorMessage>
        </FormControl>
      </FormSection>

      <FormSection title="Product Variants" description="Add the variants of the product">
        <Box flex="1">
          <Controller
            name="productAttributes"
            control={props.control}
            render={({ field: { value, onChange } }) => <ProductAttributesInput value={value} onChange={onChange} />}
          />
          <Controller
            name="productCombinations"
            control={props.control}
            render={({ field: { value, onChange } }) => (
              <ProductCombinationsTable value={value} onChange={onChange} control={props.control} />
            )}
          />
        </Box>
      </FormSection>
    </Stack>
  )
}
