import { VStack } from '@chakra-ui/react'
import { EmailSetting } from '../../components/settings/account/EmailSetting'
import { NameSetting } from '../../components/settings/account/NameSetting'
import { PasswordSetting } from '../../components/settings/account/PasswordSetting'

type Props = {}

export function AccountSettings(props: Props) {
  return (
    <VStack spacing={12}>
      <NameSetting />
      <EmailSetting />
      <PasswordSetting />
    </VStack>
  )
}
