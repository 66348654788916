import { Flex } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useImpersonateMutation } from '../graphql'

export function AdminLogin() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [login, { loading }] = useImpersonateMutation({
    onCompleted(data, clientOptions) {
      if (data.staffMemberImpersonate) {
        window.localStorage.removeItem('appId')
        navigate('/')
      }
    },
  })

  useEffect(() => {
    if (searchParams.has('code')) {
      const code = searchParams.get('code')!
      login({ variables: { code } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return (
      <Flex height="100vh" width="100vw" justifyContent="center" alignItems="center" flexDirection="column">
        Logging in ...
      </Flex>
    )
  }

  return <>Redirecting ...</>
}
