import {
  Avatar,
  Box,
  BoxProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Portal,
  Skeleton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { DeleteModal, useAuthProvider, useAuthUser, useTranslate } from 'chakra-admin'
import { useCallback, useState } from 'react'
import { NavLink } from 'react-router-dom'

type Props = {} & BoxProps

export function UserMenu({ ...rest }: Props) {
  const { initialized, user } = useAuthUser()
  const authProvider = useAuthProvider()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [loggingOut, setLoggingOut] = useState<boolean>(false)
  const toast = useToast()
  const t = useTranslate()

  const onLogout = useCallback(async () => {
    try {
      setLoggingOut(true)
      await authProvider?.logout()
      window.location.reload()
    } catch (error) {
      toast({
        title: 'Errore',
        description: 'Errore durante il logout',
        status: 'error',
        isClosable: true,
      })
    } finally {
      setLoggingOut(false)
    }
  }, [authProvider, toast])

  return (
    <>
      <DeleteModal
        resource="User"
        id={user?.id}
        deleting={loggingOut}
        isOpen={isOpen}
        onClose={onClose}
        onDeleteItem={onLogout}
        title="Logout"
        description="Sei sicuro di voler effettuare il logout?"
        confirmDeleteButtonLabel={t('ca.action.logout')}
      />

      <Box {...rest}>
        <Menu>
          <MenuButton>
            <Skeleton isLoaded={initialized} borderRadius="full">
              <Avatar size="sm" name={user?.fullName} />
            </Skeleton>
          </MenuButton>

          <Portal>
            <MenuList fontSize="sm">
              <MenuGroup title={user?.email} fontWeight="normal" fontSize="md" color="gray.500">
                <MenuItem as={NavLink} pl={4} to="/settings">
                  Settings
                </MenuItem>
              </MenuGroup>
              <MenuDivider />
              <MenuItem color="red" onClick={onOpen}>
                Logout
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>
      </Box>
    </>
  )
}
