import { Box, Flex, HStack, Icon } from '@chakra-ui/react'
import { useMemo } from 'react'
import { BsBell } from 'react-icons/bs'
import { NavLogo } from './NavLogo'
import { NavMenu } from './NavMenu'
import { NavMenuItem } from './NavMenuItem'
import { UserMenu } from './UserMenu'

export type NavBarProps = {}

export function NavBar(props: NavBarProps) {
  const appId = useMemo(() => {
    return localStorage.getItem('appId') || undefined
  }, [])

  return (
    <Box
      as="header"
      pos="fixed"
      bg="white"
      // boxShadow="sm"
      top="0"
      left="0"
      right="0"
      height="60px"
      borderBottom="1px solid"
      borderBottomColor="gray.100"
      px={8}
    >
      {/* <Container maxWidth="container.xl" px={0}> */}
      <Flex alignItems="center" w="100%" height="100%">
        <NavLogo appId={appId!} />

        <NavMenu>
          <NavMenuItem label="Products" to="/Product" />
          <NavMenuItem label="Releases" to="/Release" />
          <NavMenuItem label="Attendance" to="/Attendance" />
        </NavMenu>

        <HStack spacing={6} ml="auto" alignItems="center">
          <Icon as={BsBell} fontSize="22px" color="blackAlpha.900" />
          <UserMenu />
        </HStack>
      </Flex>
      {/* </Container> */}
    </Box>
  )
}
