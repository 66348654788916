import { Box, Link } from '@chakra-ui/react'
import { NavLink, useLocation } from 'react-router-dom'

type Props = {
  label: string
  to: string
}

export function NavMenuItem({ label, to, ...rest }: Props) {
  const location = useLocation()

  return (
    <Box as="li" {...rest}>
      <Link
        as={NavLink}
        _hover={{
          textDecoration: 'none',
          backgroundColor: 'gray.50',
        }}
        _activeLink={{
          backgroundColor: 'gray.100',
        }}
        backgroundColor={location.pathname === to ? 'gray.100' : 'transparent'}
        fontWeight="semibold"
        fontSize="sm"
        display="flex"
        alignItems="center"
        px={4}
        color="blackAlpha.800"
        minHeight="35px"
        borderRadius="md"
        to={to}
      >
        {label}
      </Link>
    </Box>
  )
}
