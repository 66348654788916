import { useCallback, useMemo, useState } from 'react'
import { gql_Release, useReleaseStartSelectionMutation } from '../../../graphql'
import {
  Box,
  Button,
  Container,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  useToast,
} from '@chakra-ui/react'

export type ReleaseStockTableProps = {
  id: string
  record: Partial<gql_Release>
}

export function ReleaseStockTable({ id, record, ...props }: ReleaseStockTableProps) {
  const [startSelection, { loading, data, error }] = useReleaseStartSelectionMutation()
  const [stock, setStock] = useState<Record<string, number>>({})
  const toast = useToast()

  const totalStock = useMemo(() => {
    return record?.product?.productCombinations?.reduce((acc, item) => {
      return acc + (stock[item.id] || 0)
    }, 0)
  }, [record?.product?.productCombinations, stock])

  const handleChange = useCallback((id: string, value: string) => {
    setStock((prev) => {
      return { ...prev, [id]: Number(value) }
    })
  }, [])

  const handleSelectWinners = useCallback(async () => {
    try {
      if ((totalStock || 0) <= 0) {
        return
      }

      await startSelection({
        variables: {
          id,
          selectionData: {
            combinations: Object.keys(stock).map((key) => {
              return {
                id: key,
                stock: stock[key],
              }
            }),
          },
        },
      })
    } catch (e) {
      toast({
        title: 'Error',
        description: (e as any).message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }, [id, stock, toast, totalStock, startSelection])

  return (
    <Container maxW="container.lg" {...props}>
      <VStack width="100%" mb={20} spacing={8}>
        <Box flex="1" display="flex" flexDir="column">
          <TableContainer>
            <Table size="sm" variant="simple">
              <Thead>
                <Tr>
                  <Th>Combination</Th>
                  <Th>Stock</Th>
                </Tr>
              </Thead>
              <Tbody>
                {record?.product?.productCombinations?.map((item) => {
                  return (
                    <ReleaseStockRow
                      combinationName={item.label}
                      id={item.id}
                      key={item.id}
                      onChange={handleChange}
                      value={stock[item.id] || 0}
                    />
                  )
                })}
                <Tr>
                  <Td fontWeight="bold">Total</Td>
                  <Td fontWeight="bold" fontSize="md" pl={6}>
                    {totalStock}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>

          <Button
            onClick={handleSelectWinners}
            isDisabled={(totalStock || 0) <= 0}
            mt={8}
            alignSelf="flex-end"
            colorScheme="blue"
            size="sm"
          >
            Select winners
          </Button>
        </Box>
      </VStack>
    </Container>
  )
}

function ReleaseStockRow({ combinationName, id, onChange, value }: any) {
  const handleChange = useCallback(
    (e: any) => {
      onChange(id, e.target.value)
    },
    [id, onChange],
  )

  return (
    <Tr bgColor={value > 0 ? 'green.50' : undefined}>
      <Td>{combinationName}</Td>
      <Td>
        <Input
          fontWeight={value > 0 ? 'bold' : undefined}
          color={value <= 0 ? 'blackAlpha.600' : undefined}
          size="xs"
          type="number"
          onChange={handleChange}
          value={value}
        />
      </Td>
    </Tr>
  )
}
