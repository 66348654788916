import React, { FC, useCallback, useMemo, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
} from '@chakra-ui/react'
import { DeleteIcon } from '@chakra-ui/icons'

export type Attribute = {
  name: string
  values: string[]
}

/**
 * Product Attributes Input
 */
export type ProductAttributesInputProps = {
  value: Attribute[]
  onChange: (value: Attribute[]) => void
}

export const ProductAttributesInput: FC<ProductAttributesInputProps> = ({ value, onChange }) => {
  const [editingAttribute, setEditingAttribute] = useState<number | undefined>(undefined)

  const checkIsNameUnique = useCallback(
    (index: number, name: string) => {
      return !value?.some(
        (item, idx) => idx !== index && item.name.toLowerCase().trim() === (name || '').toLowerCase().trim(),
      )
    },
    [value],
  )

  const handleSaveAttributeClick = useCallback(
    (idx: number, name: string, values: string[]) => {
      onChange(
        value?.map((item, index) => {
          if (index === idx) {
            return {
              ...item,
              name,
              values,
            }
          }

          return item
        }),
      )
    },
    [onChange, value],
  )

  const handleEditClick = useCallback((idx: number) => {
    setEditingAttribute(idx)
  }, [])

  const handleCloseEditClick = useCallback(() => {
    setEditingAttribute(undefined)
  }, [])

  const handleDeleteClick = useCallback(
    (idx: number) => {
      onChange(value.filter((_, index) => index !== idx))
    },
    [onChange, value],
  )

  const handleAddEmptyAttributeClick = useCallback(() => {
    const newValue = [...(value || []), { name: '', values: [''] }]
    onChange(newValue)
    handleEditClick?.(newValue.length - 1)
  }, [handleEditClick, onChange, value])

  if (!value || value?.length === 0) {
    return (
      <Box mt={8} mb={4}>
        <Button variant="link" size="xs" onClick={handleAddEmptyAttributeClick}>
          Add the first attribute
        </Button>
      </Box>
    )
  }

  return (
    <Box mt={8} mb={4}>
      {value?.map((attribute, idx) => (
        <ProductAttributeItem
          index={idx}
          key={attribute.name}
          name={attribute.name}
          values={attribute.values}
          isEditing={editingAttribute === idx}
          onEdit={handleEditClick}
          onCloseEdit={handleCloseEditClick}
          onDelete={handleDeleteClick}
          onSave={handleSaveAttributeClick}
          checkIsNameUnique={checkIsNameUnique}
        />
      ))}

      <Button
        isDisabled={editingAttribute !== undefined}
        variant="link"
        size="xs"
        onClick={handleAddEmptyAttributeClick}
      >
        Add Attribute
      </Button>
    </Box>
  )
}

/**
 * Product Attribute Item
 */
export type ProductAttributeItemProps = {
  index: number
  name: string
  checkIsNameUnique?: (idx: number, name: string) => boolean
  values: string[]
  isEditing?: boolean
  onEdit?: (idx: number) => void
  onCloseEdit?: (idx: number) => void
  onDelete?: (idx: number) => void
  onSave?: (idx: number, name: string, values: string[]) => void
}

export const ProductAttributeItem: FC<ProductAttributeItemProps> = ({
  name,
  checkIsNameUnique,
  values,
  isEditing,
  onEdit,
  onCloseEdit,
  onDelete,
  onSave,
  index,
}) => {
  const [tempName, setTempName] = useState(name)
  const [isTempNamePristine, setIsTempNamePristine] = useState(!name)
  const [tempValues, setTempValues] = useState(values)

  const nameError = useMemo(() => {
    if (!isTempNamePristine) {
      if (!tempName) {
        return 'Please insert a name'
      }

      if (!checkIsNameUnique?.(index, tempName)) {
        return 'The name must be unique'
      }
    }

    return undefined
  }, [checkIsNameUnique, index, isTempNamePristine, tempName])

  const isFormInvalid = useMemo(() => {
    return isTempNamePristine || !!nameError
  }, [isTempNamePristine, nameError])

  const checkIsValueUnique = useCallback(
    (index: number, name: string) => {
      return !tempValues?.some((item, idx) => idx !== index && item.toLowerCase().trim() === name.toLowerCase().trim())
    },
    [tempValues],
  )

  const handleEditClick = useCallback(() => {
    onEdit?.(index)
  }, [index, onEdit])

  const handleDeleteClick = useCallback(() => {
    onDelete?.(index)
    onCloseEdit?.(index)
  }, [index, onCloseEdit, onDelete])

  const handleDeleteItem = useCallback(
    (idx: number) => {
      setTempValues((prev) => prev.filter((_, index) => index !== idx))
    },
    [setTempValues],
  )

  const handleSaveClick = useCallback(() => {
    if (isFormInvalid) {
      return
    }

    const cleanedValues = (tempValues || [])?.filter((value) => value !== '')

    onSave?.(index, tempName, cleanedValues)
    onCloseEdit?.(index)
  }, [index, isFormInvalid, onCloseEdit, onSave, tempName, tempValues])

  const handleTempNameChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>((e) => {
    setTempName(e.target.value)
    setIsTempNamePristine(false)
  }, [])

  const handleTempValuesChange = useCallback((idx: number, value: string) => {
    setTempValues((prev) => {
      console.log('prev', prev)
      const newValue = [...prev]
      newValue[idx] = value

      return newValue
    })
  }, [])

  return (
    <Box pb={2}>
      {!isEditing ? (
        <Box>
          {name}
          <Button variant="outline" size="xs" onClick={handleEditClick}>
            Edit
          </Button>
        </Box>
      ) : (
        <Box>
          <Flex pb={6} alignItems="flex-end">
            <FormControl flex="1" size="sm" isInvalid={!!nameError}>
              <FormLabel fontSize="sm">Attribute Name</FormLabel>
              <Flex>
                <Input
                  minW={{ base: '100%', lg: '50%' }}
                  value={tempName}
                  onChange={handleTempNameChange}
                  variant="outline"
                  size="sm"
                  borderRadius="lg"
                  placeholder="Insert a name"
                  isInvalid={!!nameError}
                />
                <IconButton
                  ml={2}
                  variant="ghost"
                  size="sm"
                  borderRadius="full"
                  aria-label="Remove"
                  icon={<DeleteIcon />}
                  onClick={handleDeleteClick}
                />
              </Flex>

              {nameError && <FormErrorMessage>{nameError}</FormErrorMessage>}
            </FormControl>
          </Flex>

          <Box pl={4}>
            {tempValues?.map((value, idx) => (
              <ProductAttributeValueItem
                key={`${value}-${idx}`}
                index={idx}
                value={value}
                onChange={handleTempValuesChange}
                onDelete={handleDeleteItem}
                values={tempValues}
                pushValue={() => {
                  setTempValues((prev) => [...prev, ''])
                }}
                removeLastValue={() => {
                  setTempValues((prev) => prev.slice(0, prev.length - 1))
                }}
                checkIsValueUnique={checkIsValueUnique}
              />
            ))}
          </Box>

          <Button isDisabled={isFormInvalid} colorScheme="blue" variant="outline" size="xs" onClick={handleSaveClick}>
            Save
          </Button>
        </Box>
      )}
      <Divider mt={4} />
    </Box>
  )
}

/**
 * Product Attribute Value Item
 */

type ProductAttributeValueItemProps = {
  value: string
  onChange: (idx: number, value: string) => void
  index: number
  onDelete?: (idx: number) => void
  values?: string[]
  pushValue?: () => void
  removeLastValue?: () => void
  checkIsValueUnique?: (idx: number, name: string) => boolean
}

const ProductAttributeValueItem: FC<ProductAttributeValueItemProps> = ({
  value,
  onChange,
  index,
  onDelete,
  values,
  pushValue,
  removeLastValue,
  checkIsValueUnique,
}) => {
  const [tempValue, setTempValue] = useState(value)
  const [isTempValuePristine, setIsTempValuePristine] = useState(!value)

  const valueError = useMemo(() => {
    if (!isTempValuePristine) {
      if (!tempValue && (values?.length || 0) - 1 !== index) {
        return 'Please insert a value'
      }

      if (!checkIsValueUnique?.(index, tempValue)) {
        return 'The value must be unique'
      }
    }

    return undefined
  }, [checkIsValueUnique, index, isTempValuePristine, tempValue, values?.length])

  const handleBlur = useCallback<React.FocusEventHandler<HTMLInputElement>>(
    (e) => {
      if (e.target.value !== '') {
        setTempValue(e.target.value)
        onChange(index, e.target.value)
      } else if (tempValue !== '') {
        setTempValue(values?.[index] || '')
        if ((values || [])?.length - 1 === index) {
          pushValue?.()
        }
      }
    },
    [index, onChange, pushValue, tempValue, values],
  )

  const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const newValue = e.target.value
      setTempValue(newValue)
      if ((values?.length || 0) - 1 === index && newValue !== '') {
        pushValue?.()
      } else if ((values?.length || 0) - 2 === index && newValue === '') {
        removeLastValue?.()
      }

      setIsTempValuePristine(false)
    },
    [index, pushValue, removeLastValue, values],
  )

  const handleDeleteClick = useCallback(() => {
    onDelete?.(index)
  }, [index, onDelete])

  return (
    <FormControl display="flex" flexDirection="column" alignItems="flex-end" isInvalid={!!valueError}>
      <Flex flex="1">
        <Input
          type="text"
          value={tempValue}
          onChange={handleChange}
          onBlur={handleBlur}
          size="sm"
          isInvalid={!!valueError}
        />

        {(values || [])?.length > 1 && value && (
          <IconButton
            ml={2}
            variant="ghost"
            size="sm"
            borderRadius="full"
            aria-label="Remove"
            icon={<DeleteIcon />}
            onClick={handleDeleteClick}
          />
        )}
      </Flex>
      {valueError && <FormErrorMessage>{valueError}</FormErrorMessage>}
    </FormControl>
  )
}
