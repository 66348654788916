import {
  Button,
  Icon,
  IconButton,
  InputProps,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  usePopoverContext,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import React from 'react'
import { DatetimePicker } from '../datetime-picker'
import {
  addDays,
  addMonths,
  endOfMonth,
  format,
  isValid,
  startOfMonth,
  subDays,
  startOfWeek,
  endOfWeek,
  subWeeks,
  addWeeks,
  startOfQuarter,
  endOfQuarter,
  addQuarters,
  startOfYear,
  endOfYear,
  addYears,
  subMonths,
} from 'date-fns'
import { FiCalendar, FiTrash2, FiX } from 'react-icons/fi'
import { useUpdateEffect } from 'react-use'
import { ReactDatePickerProps } from 'react-datepicker'
import { dateOnly } from '../../utils/formatters'
import { useTranslate } from 'chakra-admin'

export const extract = (value: string | undefined, target: 0 | 1) => {
  if (!value) {
    return null
  }

  const dates = value.split(',')

  if (target === 1 && dates.length === 1) {
    return null
  }

  const date = new Date(dates[target])

  if (isValid(date)) {
    return date
  }

  return null
}

export const convertToRangeFilter = (value?: string) => {
  if (!value) {
    return
  }
  return {
    start: dateOnly(extract(value, 0)),
    end: dateOnly(extract(value, 1)),
  }
}

interface DateRangeFilterProps {
  source?: string
  label?: string
  value?: string
  onChange?: (value?: string) => void
  size?: InputProps['size']
  pickerProps?: Omit<ReactDatePickerProps, 'selected' | 'onChange'>
  inputProps?: Omit<InputProps, 'onChange' | 'value'>
  monthPicker?: boolean
  isInline?: boolean
  showHelpers?: 'all' | 'month'
}

export enum ElapsedPeriod {
  today = 'today',
  yesterday = 'yesterday',
  tomorrow = 'tomorrow',
  this_month = 'this_month',
  next_month = 'next_month',
  this_week = 'this_week',
  previous_week = 'previous_week',
  next_week = 'next_week',
  this_quarter = 'this_quarter',
  next_quarter = 'next_quarter',
  this_halfyear = 'this_halfyear',
  next_halfyear = 'next_halfyear',
  this_year = 'this_year',
  next_year = 'next_year',
}

const dayLevel = [
  ElapsedPeriod.today,
  ElapsedPeriod.yesterday,
  ElapsedPeriod.tomorrow,
  ElapsedPeriod.this_week,
  ElapsedPeriod.previous_week,
  ElapsedPeriod.next_week,
]

const getPeriodDate = (period: ElapsedPeriod): [Date, Date] => {
  const today = new Date()
  switch (period) {
    case ElapsedPeriod.today: {
      return [today, today]
    }
    case ElapsedPeriod.yesterday: {
      return [subDays(today, 1), subDays(today, 1)]
    }
    case ElapsedPeriod.tomorrow: {
      return [addDays(today, 1), addDays(today, 1)]
    }
    case ElapsedPeriod.next_month: {
      return [startOfMonth(today), endOfMonth(today)]
    }
    case ElapsedPeriod.this_month: {
      const nextMonth = addMonths(today, 1)
      return [startOfMonth(nextMonth), endOfMonth(nextMonth)]
    }
    case ElapsedPeriod.this_week: {
      return [startOfWeek(today), endOfWeek(today)]
    }
    case ElapsedPeriod.previous_week: {
      const previousWeek = subWeeks(today, 1)
      return [startOfWeek(previousWeek), endOfWeek(previousWeek)]
    }
    case ElapsedPeriod.next_week: {
      const nextWeek = addWeeks(today, 1)
      return [startOfWeek(nextWeek), endOfWeek(nextWeek)]
    }
    case ElapsedPeriod.this_quarter: {
      return [startOfQuarter(today), endOfQuarter(today)]
    }
    case ElapsedPeriod.next_quarter: {
      const nextQuarter = addQuarters(today, 1)
      return [startOfQuarter(nextQuarter), endOfQuarter(nextQuarter)]
    }
    case ElapsedPeriod.this_halfyear: {
      if (today.getMonth() < 6) {
        const starts = startOfYear(today)
        return [starts, endOfMonth(addMonths(starts, 5))]
      }
      const ends = endOfYear(today)
      return [startOfMonth(subMonths(ends, 6)), ends]
    }
    case ElapsedPeriod.next_halfyear: {
      const [start, end] = getPeriodDate(ElapsedPeriod.this_halfyear)
      return [addMonths(start, 6), addMonths(end, 5)]
    }
    case ElapsedPeriod.this_year: {
      return [startOfYear(today), endOfYear(today)]
    }
    case ElapsedPeriod.next_year: {
      const nextYear = addYears(today, 1)
      return [startOfYear(nextYear), endOfYear(nextYear)]
    }
  }
}

export const DateRangeFilter: React.FC<DateRangeFilterProps> = ({
  label = '',
  value,
  onChange,
  size,
  pickerProps,
  inputProps,
  monthPicker = false,
  isInline = true,
  showHelpers,
}) => {
  const t = useTranslate()
  const [startDate, setStartDate] = React.useState<Date | null>(extract(value, 0))
  const [endDate, setEndDate] = React.useState<Date | null>(extract(value, 1))

  useUpdateEffect(() => {
    if (startDate || endDate) {
      onChange?.(`${startDate ? format(startDate, 'yyyy-MM-dd') : ''},${endDate ? format(endDate, 'yyyy-MM-dd') : ''}`)
    } else {
      onChange?.()
    }
  }, [startDate, endDate])

  const handleHelperClick = (type: ElapsedPeriod) => {
    const [start, end] = getPeriodDate(type)

    setStartDate(start)
    setEndDate(end)
  }

  const periods = React.useMemo(() => {
    switch (showHelpers) {
      case 'all':
        return Object.values(ElapsedPeriod)
      case 'month':
        return Object.values(ElapsedPeriod).filter((key) => !dayLevel.includes(key))
    }
  }, [showHelpers])

  return (
    <Stack spacing="1">
      <Stack spacing="2" isInline={isInline}>
        <DatetimePicker
          placeholderText={`${label} dal`}
          isClearable
          {...pickerProps}
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          inputProps={{
            variant: 'filled',
            size,
            ...inputProps,
          }}
        />
        <DatetimePicker
          placeholderText={`${label} fino a`}
          isClearable
          {...pickerProps}
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          inputProps={{
            variant: 'filled',
            size,
            ...inputProps,
          }}
        />
        {monthPicker && (
          <Popover>
            <PopoverTrigger>
              <IconButton
                aria-label="Select month"
                size={size}
                bgColor="gray.100"
                borderRadius="xl"
                icon={<Icon as={FiCalendar} />}
              />
            </PopoverTrigger>
            <PopoverContent w="100%" borderColor="transparent" bg="transparent" boxShadow="none">
              <PopoverBody p="0">
                <MonthPicker
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(date) => {
                    if (date) {
                      setStartDate(date)
                      setEndDate(endOfMonth(date))
                    }
                  }}
                />
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )}
        <IconButton
          aria-label="Clear"
          icon={<Icon as={FiTrash2} />}
          variant="ghost"
          colorScheme="red"
          isDisabled={!startDate || !endDate}
          onClick={() => {
            setStartDate(null)
            setEndDate(null)
          }}
          size={size}
        />
      </Stack>
      {periods && (
        <Wrap spacing="1">
          {periods.map((key) => (
            <WrapItem key={key}>
              <Button size="xs" onClick={() => handleHelperClick(key)}>
                {t(`components.DateRangeFilter.periods.${key}`)}
              </Button>
            </WrapItem>
          ))}
        </Wrap>
      )}
    </Stack>
  )
}

const MonthPicker: React.FC<{
  startDate: Date | null
  endDate: Date | null
  onChange: (date: Date | null) => void
}> = ({ onChange, startDate, endDate }) => {
  const { onClose } = usePopoverContext()

  return (
    <DatetimePicker
      selected={startDate || endDate}
      onChange={(date) => {
        onChange(date)
        onClose()
      }}
      dateFormat="MM/yyyy"
      showMonthYearPicker
      showFullMonthYearPicker
      inline
    />
  )
}
