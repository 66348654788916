import { Button, ButtonProps, useToast } from '@chakra-ui/react'
import {
  gql_ReleaseDrawQueueReportSubscription,
  gql_ReportStatus,
  useGetReleaseDrawQueueStatusQuery,
  useReleaseRequestDrawMutation,
} from '../../../graphql'
import { useCallback, useEffect, useMemo } from 'react'
import { useVersion } from 'chakra-admin'
import { SUBSCRIPTION_RELEASE_DRAW_QUEUE_REPORT } from '../../../queries'

export type RequestDrawButtonProps = { record?: any } & ButtonProps

export function RequestDrawButton({ record, ...props }: RequestDrawButtonProps) {
  const [requestDraw, { loading, data, error }] = useReleaseRequestDrawMutation()
  const toast = useToast()
  const nextVersion = useVersion()

  const {
    loading: dqsLoading,
    data: dqsData,
    error: dqsError,
    networkStatus: dqsNetworkStatus,
    subscribeToMore: dqsSubscribeToMore,
  } = useGetReleaseDrawQueueStatusQuery({
    variables: {
      id: props?.id || '',
    },
    skip: !props?.id,
  })

  const handleClick = useCallback(async () => {
    try {
      await requestDraw({
        variables: {
          id: props?.id!,
        },
      })
      toast({
        title: 'Draw requested',
        description: 'The draw has been requested',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
    } catch (err) {
      console.error(err)
      toast({
        title: 'Error requesting draw',
        description: (err as any).message,
        status: 'error',
        duration: 1000,
        isClosable: true,
      })
    }
  }, [props?.id, requestDraw, toast])

  const isLoading = useMemo(() => {
    return (
      dqsLoading ||
      loading ||
      dqsData?.releaseDrawQueueStatus?.status === gql_ReportStatus.Pending ||
      dqsData?.releaseDrawQueueStatus?.status === gql_ReportStatus.Processing
    )
  }, [dqsData?.releaseDrawQueueStatus?.status, dqsLoading, loading])

  useEffect(() => {
    if (!props?.id) {
      return
    }

    const drawQueueUnsub = dqsSubscribeToMore<gql_ReleaseDrawQueueReportSubscription>({
      document: SUBSCRIPTION_RELEASE_DRAW_QUEUE_REPORT,
      variables: { id: props?.id || '' },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev
        }

        const newEntry = subscriptionData.data.releaseDrawQueueReport
        return Object.assign({}, prev, {
          releaseDrawQueueStatus: newEntry,
        })
      },
    })

    return () => {
      drawQueueUnsub()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.id])

  if (!record?.id || record?.status === 'OPEN') {
    return null
  }

  return (
    <Button {...props} isDisabled={isLoading} isLoading={isLoading} onClick={handleClick}>
      Request draw
    </Button>
  )
}
