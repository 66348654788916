import { Button } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { gql_Release } from '../../../graphql'

export type ReleaseSelectWinnersButtonProps = {
  record?: Partial<gql_Release>
}

export function ReleaseSelectWinnersButton({ record, ...props }: ReleaseSelectWinnersButtonProps) {
  if (record?.status !== 'OPEN' && record?.status !== 'SELECTING') {
    return null
  }

  return (
    <Button as={Link} to="select-winners" {...props}>
      Seleziona Vincitori
    </Button>
  )
}
