import {
  Box,
  Button,
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  ModalProps,
  Heading,
  Divider,
  Image,
  chakra,
  BoxProps,
  ButtonProps,
  Icon,
} from '@chakra-ui/react'
import { FiX } from 'react-icons/fi'
import hypertingLogo from '../../../../assets/icons/hyperting.png'

export type ConnectModalProps = {
  children: React.ReactNode
  onConfirmClick: () => void
  confirmText?: string
  confirmButtonProps?: ButtonProps
} & Omit<ModalProps, 'children'> &
  AuthorizeAppHeaderProps

export function ConnectModal({
  rightApp,
  title,
  description,
  children,
  confirmText = 'Connect',
  onConfirmClick,
  confirmButtonProps,
  isDisconnect,
  ...props
}: ConnectModalProps) {
  return (
    <Modal isCentered closeOnEsc={false} closeOnOverlayClick={false} {...props}>
      <ModalOverlay />
      <ModalContent borderRadius="xl">
        <AuthorizeAppHeader rightApp={rightApp} title={title} description={description} isDisconnect={isDisconnect} />
        <ModalBody>{children}</ModalBody>

        <ModalFooter display="grid" gridTemplateColumns="1fr 1fr" gridColumnGap={4}>
          <Button
            variant="outline"
            fontWeight="normal"
            fontSize="sm"
            borderRadius="lg"
            boxShadow="sm"
            onClick={props.onClose}
          >
            Cancel
          </Button>
          <Button
            onClick={onConfirmClick}
            variant="solid"
            fontSize="sm"
            fontWeight="normal"
            borderRadius="lg"
            boxShadow="sm"
            colorScheme="primary"
            {...confirmButtonProps}
          >
            {confirmText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export type AuthorizeAppIconProps = {
  children: React.ReactNode
} & BoxProps

export function AuthorizeAppIcon({ children, ...props }: AuthorizeAppIconProps) {
  return (
    <Box
      borderRadius="full"
      border="1px solid"
      borderColor="gray.100"
      bgColor="gray.50"
      boxShadow="sm"
      boxSize="60px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      overflow="hidden"
      {...props}
    >
      {children}
    </Box>
  )
}

export type AuthorizeAppHeaderProps = {
  rightApp: React.ReactNode
  title: string
  description: string
  isDisconnect?: boolean
}

export function AuthorizeAppHeader({ rightApp, title, description, isDisconnect }: AuthorizeAppHeaderProps) {
  return (
    <Flex pt={8} direction="column" alignItems="center">
      <Grid mb={6} templateColumns="60px 60px 60px" columnGap={0} alignItems="center">
        <AuthorizeAppIcon>
          <Image boxSize="40px" src={hypertingLogo} />
        </AuthorizeAppIcon>
        <Box display="flex" alignItems="center" justifyContent="center" position="relative">
          {isDisconnect ? (
            <>
              <chakra.svg mt={0.5} viewBox="0 0 60 3" preserveAspectRatio="none">
                <chakra.path
                  d="M 3 0 L 57 0"
                  vector-effect="non-scaling-stroke"
                  stroke="blackAlpha.300"
                  strokeWidth="3"
                  strokeDasharray="5, 5"
                />
              </chakra.svg>
              <Icon
                as={FiX}
                boxSize="30px"
                color="white"
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                bgColor="red.500"
                borderRadius="full"
              />
            </>
          ) : (
            <chakra.svg mt={0.5} viewBox="0 0 60 15" preserveAspectRatio="none">
              <chakra.path
                d="M 3 0 L 57 0"
                vector-effect="non-scaling-stroke"
                stroke="blackAlpha.300"
                strokeWidth="3"
                strokeDasharray="5, 5"
              />
              <chakra.path
                d="M 3 15 L 57 15"
                vector-effect="non-scaling-stroke"
                stroke="blackAlpha.300"
                strokeWidth="3"
                strokeDasharray="5, 5"
              />
            </chakra.svg>
          )}
        </Box>
        <Box>{rightApp}</Box>
      </Grid>

      <Heading as="h2" size="sm" mb={3} fontWeight="semibold" color="blackAlpha.700">
        {title}
      </Heading>
      <Text color="blackAlpha.600" fontSize="sm" mb={6}>
        {description}
      </Text>
      <Divider />
    </Flex>
  )
}
