import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { Box, Table, TableContainer, Tbody, Th, Thead, Tr, Input, Td } from '@chakra-ui/react'
import { useWatch } from 'react-hook-form'
import { cartesian } from '../../utils/cartesian-product'

export type ProductCombinationOption = {
  attribute: string
  value: string
}

export type ProductCombination = {
  label?: string
  integrationCode?: string
  barcode?: string
  weightKg?: number
  price: number
  priceTaxes: number
  options: ProductCombinationOption[]
}

/**
 * ProductCombinationTable
 */

export type ProductCombinationsTableProps = {
  value: ProductCombination[] | undefined
  onChange?: (value: ProductCombination[]) => void
  control?: any
}

const emptyCombination = {
  label: '',
  integrationCode: '',
  barcode: '',
  weightKg: 0,
  price: 0,
  priceTaxes: 0,
  options: [],
}

export const ProductCombinationsTable: FC<ProductCombinationsTableProps> = ({ control, onChange, value }) => {
  const productAttributes = useWatch({
    control,
    name: 'productAttributes',
  })

  const handleChangeCombination = useCallback(
    (index: number, combination: ProductCombination) => {
      const newValue = [...(value || [])]
      newValue[index] = combination
      onChange?.(newValue)
    },
    [onChange, value],
  )

  useEffect(() => {
    // remove empty attributes and empty string from values
    const cleanedProductAttributes = (productAttributes || [])?.filter((attribute: any) => {
      if (attribute.name === '' || attribute.values?.length === 0 || attribute.values?.[0] === '') {
        return false
      }

      return true
    })

    // calculate all possible combinations
    const lookupValues = (cleanedProductAttributes || [])?.map(
      (attribute: any) => attribute?.values?.map((value: any) => value),
    )

    if (!lookupValues?.length) {
      onChange?.([])
      return
    }

    const lookupCombinations = cartesian(...(lookupValues || []))

    const combinations = (lookupCombinations || []).map((combination: string[] | string) => {
      if (typeof combination === 'string') {
        return {
          ...emptyCombination,
          label: combination,
          options: [
            {
              attribute: cleanedProductAttributes[0].name,
              value: combination,
            },
          ],
        }
      }

      const options = (combination || []).map((value, index) => ({
        attribute: cleanedProductAttributes[index].name,
        value,
      }))
      return {
        ...emptyCombination,
        label: combination.join(' / '),
        options,
      }
    })
    console.log('productAttributes changed', combinations)
    onChange?.(combinations)
  }, [onChange, productAttributes])

  return (
    <Box>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Combination</Th>
              <Th minW="150px">Price</Th>
              <Th>subtotal</Th>
              <Th>Taxes</Th>
            </Tr>
          </Thead>
          <Tbody>
            {(value || []).map((combination, index) => {
              const combinationKey = combination?.options?.map((item) => item.value).join?.('-')

              return (
                <ProductCombinationRow
                  index={index}
                  combination={combination}
                  key={combinationKey}
                  onChange={handleChangeCombination}
                  control={control}
                />
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  )
}

/**
 * ProductCombinationRow
 */

type ProductCombinationRowProps = {
  index: number
  combination: ProductCombination
  onChange?: (index: number, value: ProductCombination) => void
  control?: any
}

const calculateProductSubtotal = (price: number, taxRate: number) => {
  // product price without taxes
  return (100 * price) / (100 + taxRate || 0)
}

const calculatePriceTaxes = (price: number, taxRate: number) => {
  // product price without taxes
  return price - calculateProductSubtotal(price, taxRate)
}

export const ProductCombinationRow: FC<ProductCombinationRowProps> = ({ index, combination, onChange, control }) => {
  const taxRate = useWatch({
    control,
    name: 'taxRate',
  })
  const [tempPrice, setTempPrice] = React.useState(`${combination.price}`)

  const productSubtotal = useMemo(() => {
    // product price without taxes
    let parsedFloat
    try {
      parsedFloat = parseFloat(tempPrice)
    } catch (error) {
      parsedFloat = 0
    }

    return calculateProductSubtotal(parsedFloat, taxRate)
  }, [taxRate, tempPrice])

  const priceTaxes = useMemo(() => {
    // product price without taxes
    let parsedFloat
    try {
      parsedFloat = parseFloat(tempPrice)
    } catch (error) {
      parsedFloat = 0
    }
    return calculatePriceTaxes(parsedFloat, taxRate)
  }, [taxRate, tempPrice])

  const handleChangePrice = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const value = event.target.value
      setTempPrice(value)
    },
    [setTempPrice],
  )

  const handlePriceBlur = useCallback(() => {
    let parsedFloat
    try {
      parsedFloat = parseFloat(tempPrice)
    } catch (error) {
      parsedFloat = 0
    }

    onChange?.(index, {
      ...combination,
      price: parsedFloat,
      priceTaxes: calculatePriceTaxes(parsedFloat, taxRate),
    })
  }, [combination, index, onChange, taxRate, tempPrice])

  return (
    <Tr>
      <Th>{combination.label}</Th>
      <Td>
        <Input
          type="number"
          min={0}
          step={0.01}
          onChange={handleChangePrice}
          onBlur={handlePriceBlur}
          value={tempPrice}
          size="sm"
        />
      </Td>
      <Td>
        <Input type="text" size="sm" isDisabled value={productSubtotal} />
      </Td>
      <Td>
        <Input type="text" size="sm" isDisabled value={priceTaxes} />
      </Td>
    </Tr>
  )
}
