/* eslint-disable import/no-anonymous-default-export */
import { GiConverseShoe } from 'react-icons/gi'
import { ProductList } from './ProductList'
import { ProductCreate } from './ProductCreate'
import { ProductEdit } from './ProductEdit'

export default {
  icon: GiConverseShoe,
  name: 'Product',
  list: ProductList,
  create: ProductCreate,
  edit: ProductEdit,
}
