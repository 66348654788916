import { useMemo } from 'react'
import { gql_ProductCombination, gql_Release, gql_ReleaseStats } from '../../../graphql'
import { Box, Icon, SimpleGrid } from '@chakra-ui/react'
import { FiUser } from 'react-icons/fi'

export type CombinationsOverviewProps = {
  record?: Partial<gql_Release>
  releaseStats?: Partial<gql_ReleaseStats>
}

export function CombinationsOverview({ record, releaseStats, ...props }: CombinationsOverviewProps) {
  const selectionStockMap = useMemo(() => {
    return record?.selectionData?.combinations?.reduce?.((acc: any, item: any) => {
      return { ...acc, [item.id]: item.stock }
    }, {})
  }, [record?.selectionData])

  const productCombinations = useMemo(() => {
    return record?.product?.productCombinations?.filter((item) => {
      return selectionStockMap?.[item.id] > 0
    })
  }, [record?.product?.productCombinations, selectionStockMap])

  return (
    <SimpleGrid mx={4} mb={8} mt={4} minChildWidth="70px" spacing={2} {...props}>
      {productCombinations?.map((item) => {
        console.log('stats', { item, releaseStats })
        return (
          <CombinationItem
            key={item.id}
            stock={selectionStockMap?.[item.id] || 0}
            combination={item}
            releaseStats={releaseStats?.perCombination?.[item.id]}
          />
        )
      })}
    </SimpleGrid>
  )
}

type CombinationItemProps = {
  stock: number
  releaseStats?: Omit<gql_ReleaseStats, 'perCombination'>
  combination: gql_ProductCombination
}

function CombinationItem({ stock, releaseStats, combination }: CombinationItemProps) {
  const confirmedPercentage = useMemo(() => {
    console.log('confirmedPercentage', releaseStats?.confirmed, stock)
    return ((releaseStats?.confirmed || 0) / stock) * 100
  }, [releaseStats, stock])

  const selectedForConfirmationPercentage = useMemo(() => {
    console.log('selectedForConfirmationPercentage', releaseStats?.selectedForConfirmation, stock)
    return ((releaseStats?.selectedForConfirmation || 0) / stock) * 100
  }, [releaseStats, stock])

  return (
    <Box key={combination.id} display="flex" flexDirection="column" alignItems="center" h="90px">
      <Box
        flex="1"
        width="100%"
        border="1px solid"
        borderRadius="md"
        h="70px"
        py={0.5}
        px={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection="column"
        textAlign="center"
        borderColor="gray.200"
        position="relative"
      >
        <Box
          zIndex={-1}
          position="absolute"
          bottom="0"
          right="0"
          left="0"
          height={`${confirmedPercentage}%`}
          bgColor="green.300"
          borderBottomRadius="md"
        />
        <Box
          zIndex={-1}
          position="absolute"
          bottom={`${confirmedPercentage}%`}
          right="0"
          left="0"
          height={`${selectedForConfirmationPercentage}%`}
          bgColor="blue.300"
          borderTopRadius="md"
        />
        <Box />

        <Box as="span" color="black" fontWeight="bold" fontSize="sm">
          {releaseStats?.confirmed || 0}/{stock || 0}
        </Box>

        <Box as="span" color="black" fontWeight="bold" fontSize="xx-small" display="flex" alignItems="center">
          <Icon as={FiUser} boxSize="10px" mr={1} />
          {releaseStats?.total || 0}
        </Box>
      </Box>

      <Box width="100%" textAlign="center" as="span" color="black" fontSize="xs">
        {combination.label}
      </Box>
    </Box>
  )
}
