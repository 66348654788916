import { useToast } from '@chakra-ui/react'
import {
  gql_ReportStatus,
  useReleaseDrawQueueReportSubscription,
  useReleaseFullfillmentQueueReportSubscription,
} from '../../../graphql'
import { useVersion } from 'chakra-admin'

export type QueueWatcherProps = {
  refetch?: (vars: any) => void
  id?: string
}

export function QueueWatcher({ refetch, id }: QueueWatcherProps) {
  const toast = useToast()
  const nextVersion = useVersion()

  useReleaseDrawQueueReportSubscription({
    variables: {
      id: id || '',
    },
    onData: ({ data }) => {
      // refetch?.({ id })
      nextVersion()

      if (data?.data?.releaseDrawQueueReport?.status === gql_ReportStatus.Complete) {
        toast({
          title: 'Draw completed',
          description: data?.data?.releaseDrawQueueReport?.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
      }

      if (data?.data?.releaseDrawQueueReport?.status === gql_ReportStatus.Error) {
        toast({
          title: 'Draw Error!',
          description: data?.data?.releaseDrawQueueReport?.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
    },
    skip: !id,
  })

  useReleaseFullfillmentQueueReportSubscription({
    variables: {
      id: id || '',
    },
    onData: ({ data }) => {
      // refetch?.({ id })
      nextVersion()

      if (data?.data?.releaseFullfillmentQueueReport?.status === gql_ReportStatus.Complete) {
        toast({
          title: 'Fullfillment completed',
          description: data?.data?.releaseFullfillmentQueueReport?.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
      }

      if (data?.data?.releaseFullfillmentQueueReport?.status === gql_ReportStatus.Error) {
        toast({
          title: 'Fullfillment Error!',
          description: data?.data?.releaseFullfillmentQueueReport?.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
    },
    skip: !id,
  })

  return null
}
