import React, { FC } from 'react'
import { MUTATION_UPDATE_RELEASE, QUERY_GET_RELEASE } from '../../queries'
import { ReleaseForm } from './ReleaseForm'
import { BaseForm } from '../../components/forms/BaseForm'
import { Edit } from 'chakra-admin'
import { FormPageLayout } from '../../components/details/FormPageLayout'
import { PageTitle } from '../../components/details/PageTitle'

export const ReleaseEdit: FC = (props) => {
  return (
    <Edit
      query={QUERY_GET_RELEASE}
      mutation={MUTATION_UPDATE_RELEASE}
      layout={<FormPageLayout />}
      title={(<PageTitle />) as any}
      {...props}
    >
      <BaseForm {...props}>
        <ReleaseForm />
      </BaseForm>
    </Edit>
  )
}
