import { VStack } from '@chakra-ui/react'
import { AppNameSetting } from '../../components/settings/app/AppNameSetting'
import { AppUrlSetting } from '../../components/settings/app/AppUrlSetting'

type Props = {}

export function AppGeneralSettings(props: Props) {
  return (
    <VStack spacing={12}>
      <AppNameSetting />
      <AppUrlSetting />
    </VStack>
  )
}
