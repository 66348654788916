import { ColorModeScript } from '@chakra-ui/react'
import * as React from 'react'
import ReactDOM from 'react-dom'
import { App } from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import { subAuthToken } from './subscription-auth-token'

function Preloader() {
  const [initialized, setInitialized] = React.useState(false)

  React.useEffect(() => {
    const fetchAuthToken = async () => {
      let appId = localStorage.getItem('appId')
      try {
        const res = await fetch(process.env.REACT_APP_API_URI || '/merchant/graphql', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'X-Merchant-App-Id': appId || '',
            'X-Requested-With': 'fetch',
          },
          body: JSON.stringify({
            query: `query GetSubscriptionsAuthKey { getSubscriptionsAuthKey staffMemberMe { id } }`,
            operationName: 'GetSubscriptionsAuthKey',
            variables: {},
          }),
        })

        const { data } = await res.json()

        subAuthToken.s = data.getSubscriptionsAuthKey
        subAuthToken.u = data?.staffMemberMe?.id
      } catch (e) {
      } finally {
        setInitialized(true)
      }
    }

    fetchAuthToken()
  }, [])

  if (!initialized) {
    return null
  }

  return <App />
}

ReactDOM.render(
  <React.StrictMode>
    <ColorModeScript />
    <Preloader />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
