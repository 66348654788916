import { Box, Heading } from '@chakra-ui/react'
import { gql_GetStaffMemberMeQuery, useGetStaffMemberMeLazyQuery } from '../../graphql'
import { assign, createMachine } from 'xstate'
import { useMachine } from '@xstate/react'
import { StripeOnboarding } from './StripeOnboarding'
import { AppMenu } from './AppMenu'

type BootstrapMachineContext = {
  staffMemberMe?: gql_GetStaffMemberMeQuery['staffMemberMe'] | null
}

const bootstrapMachine = createMachine<BootstrapMachineContext>({
  id: 'bootstrap-machine',
  initial: 'loading',
  context: {
    staffMemberMe: null,
  },
  states: {
    loading: {
      invoke: {
        src: 'getStaffMemberMe',
        onDone: {
          target: 'checkingProfile',
          actions: 'setStaffMemberMe',
        },
      },
    },
    checkingProfile: {
      invoke: {
        src: 'checkProfile',
      },
      on: {
        NEEDS_STRIPE_ONBOARDING: 'stripeOnboarding',
        APP_MENU: 'appMenu',
      },
    },
    stripeOnboarding: {
      type: 'final',
    },
    appMenu: {
      type: 'final',
    },
  },
  predictableActionArguments: true,
})

export function Bootstrap() {
  const [getStaffMemberMe] = useGetStaffMemberMeLazyQuery()
  const [current, send] = useMachine(bootstrapMachine, {
    services: {
      getStaffMemberMe: async () => getStaffMemberMe(),
      checkProfile: (context, event) => async (callback) => {
        if (
          !context.staffMemberMe?.merchant?.stripeAccounts?.length ||
          !context.staffMemberMe?.merchant?.stripeAccounts.some((item) => !!item?.stripeAccount?.details_submitted)
        ) {
          callback('NEEDS_STRIPE_ONBOARDING')
          return
        }

        callback('APP_MENU')
      },
    },
    actions: {
      setStaffMemberMe: assign({
        staffMemberMe: (_, event) => {
          console.log('setStaffMemberMe', event)
          return event.data?.data?.staffMemberMe
        },
      }),
    },
  })

  return (
    <Box>
      <Heading>{current.value.toString()}</Heading>
      {current.matches('loading') && <Box>loading</Box>}
      {current.matches('stripeOnboarding') && (
        <StripeOnboarding
          stripeAccounts={current.context?.staffMemberMe?.merchant?.stripeAccounts || []}
          onStripeAccountCreated={() => send('STRIPE_ACCOUNT_COMPLETE')}
        />
      )}
      {current.matches('appMenu') && <AppMenu />}
    </Box>
  )
}
