import { StackProps, VStack } from '@chakra-ui/react'
import { gql_MerchantStripeAccount } from '../../graphql'
import { StripeAccountRow } from './StripeAccountRow'

export type StripeAccountsListProps = {
  stripeAccounts: Pick<gql_MerchantStripeAccount, 'id' | 'stripeAccount' | 'stripeAccountId'>[]
} & StackProps

export function StripeAccountsList({ stripeAccounts, ...rest }: StripeAccountsListProps) {
  return (
    <VStack w="500px" {...rest}>
      {stripeAccounts.map((item) => {
        return <StripeAccountRow key={item.id} stripeAccount={item} />
      })}
    </VStack>
  )
}
