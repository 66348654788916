import { CloseIcon } from '@chakra-ui/icons'
import { Box, IconButton, UseToastOptions } from '@chakra-ui/react'

interface Props extends UseToastOptions {
  /**
   * Function to close the toast
   */
  onClose(): void
}

export function ConfirmToast({ onClose, title, description }: Props) {
  return (
    <Box color="white" p={3} bg="blackAlpha.800" borderRadius="md" position="relative">
      {title && <Box fontWeight="semibold">{title}</Box>}
      {description && <Box fontSize="sm">{description}</Box>}
      <IconButton
        ml={2}
        size="xs"
        width="var(--close-button-size)"
        height="var(--close-button-size)"
        variant="ghost"
        borderRadius="full"
        aria-label="Close"
        icon={<CloseIcon width="10px" height="10px" />}
        _hover={{}}
        _active={{}}
        position="absolute"
        right={1}
        top={2.5}
        onClick={onClose}
      />
    </Box>
  )
}
