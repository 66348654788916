import { Box, BoxProps } from '@chakra-ui/react'

export function IntegrationBox(props: BoxProps) {
  return (
    <Box
      display="flex"
      width="100%"
      borderRadius="lg"
      border="1px solid"
      borderColor="gray.100"
      // error outline
      outlineColor="red.600"
      py={4}
      px={6}
      alignItems="center"
      height="120px"
      {...props}
    />
  )
}
